import { map } from 'ramda';
import { useMemo } from 'react';

import { getUserPageLanguage } from '../../../../pageLanguage/main/components/usePageLanguage';
import { getTranslation } from '../../../../shared/utils/translations';
import { useCustomerConfig } from '../../../../sharedModules/customerConfig/components/useCustomerConfig';
import AdviceSessionTypeSelectDropdown from './adviceSessionTypeSelectDropdown';
import Table from './table';
import { Spacing } from 'features/shared/constants/spacing';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  toolbar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: Spacing.spacing01
  }
});

const AdviceSessionsList = () => {
  const classes = useStyles();
  const { adviceFlows } = useCustomerConfig();
  const options = useMemo(
    () =>
      map(
        option => ({ key: option.name, title: getTranslation(option.label) }),
        adviceFlows
      ),
    [adviceFlows, getUserPageLanguage()]
  );

  return (
    <div>
      <div className={classes.toolbar}>
        <div>
          <AdviceSessionTypeSelectDropdown options={options} />
        </div>
      </div>
      <Table />
    </div>
  );
};

export default AdviceSessionsList;
