import create from 'zustand';
import * as R from 'ramda';

export const createUseExpectedValueChartStore = (
  initialState = {
    chartData: {},
    goalsData: []
  }
) =>
  create(set => ({
    ...initialState,
    reset: () => {
      set(initialState);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setChartData: selectedGoalsIds => {
      set(state => {
        const currentDate = new Date();

        const selectedGoalsData = selectedGoalsIds
          ? state.goalsData.filter(({ goalId }) =>
              selectedGoalsIds.includes(goalId)
            )
          : state.goalsData;

        const timeHorizon = Math.max(
          ...selectedGoalsData.map(({ timeHorizon }) => timeHorizon)
        );

        const firstDeposit = selectedGoalsData.reduce((acc, goal) => {
          return acc + (goal.firstDeposit || 0);
        }, 0);

        const forecast = selectedGoalsData
          .map(({ forecast }) => forecast)
          .flat()
          .reduce((acc, curr) => {
            const index = acc.findIndex(({ date }) => date === curr.date);
            if (index !== -1) {
              return acc.map((item, i) =>
                i === index
                  ? R.mergeWithKey(
                      (k, l, r) => (k === 'date' ? r : R.add(l, r)),
                      acc[index],
                      curr
                    )
                  : item
              );
            }
            return [...acc, curr];
          }, []);

        const chartData = {
          expectedValueTable: mapServerDataToExpectedValueTableData(
            timeHorizon,
            currentDate,
            firstDeposit,
            forecast
          ),
          savingPlan: mapServerDataToSavingPlanChartData(
            timeHorizon,
            currentDate,
            firstDeposit,
            forecast
          ),
          savingInBankAccount: mapServerDataToSavingInBankAccountChartData(
            timeHorizon,
            currentDate,
            firstDeposit,
            forecast
          )
        };

        return { chartData };
      });
    },
    addGoalData: goalData => {
      set(state => ({ ...state, goalsData: [...state.goalsData, goalData] }));
    },
    editGoalData: goalData => {
      set(state => ({
        ...state,
        goalsData: state.goalsData.map(data => {
          if (goalData.goalId === data.goalId) {
            return goalData;
          }
          return data;
        })
      }));
    },
    resetChartData: () => {
      set({
        chartData: R.propOr({}, 'chartData', initialState),
        goalsData: R.propOr([], 'goalsData', initialState)
      });
    }
  }));

const filterExpectedValueServerData = (
  yearOffset,
  currentDate,
  firstDeposit,
  forecast
) => {
  const { currentMonth, currentYear } = currentDate;
  const finalYear = currentYear + yearOffset;

  return R.pipe(
    R.map(i => {
      const { date: dateStr, ...restProps } = i;
      const date = new Date(dateStr);

      return {
        ...restProps,
        year: date.getFullYear(),
        month: date.getMonth()
      };
    }),
    R.filter(i => i.year > currentYear),
    R.append({
      year: currentYear,
      month: currentMonth,
      acc_deposit: firstDeposit,
      acc_return: 0,
      acc_return_bank: 0,
      value: firstDeposit,
      value_bank: firstDeposit
    }),
    R.groupBy(R.prop('year')),
    R.toPairs,
    R.filter(g => g[0] <= finalYear),
    R.sort(R.ascend(R.prop(0))),
    R.map(g => {
      const items = g[1];
      const currentMonthItem = R.find(i => i.month === currentMonth, items);

      return currentMonthItem;
    }),
    R.reject(R.isNil)
  )(forecast);
};

const mapServerDataToExpectedValueTableData = (
  timeHorizon,
  currentDate,
  firstDeposit,
  forecast
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const filteredData = R.filter(
    i => i.year > currentYear,
    filterExpectedValueServerData(
      timeHorizon,
      {
        currentYear,
        currentMonth
      },
      firstDeposit,
      forecast
    )
  );

  return R.map(
    i => ({
      accDeposit: i.acc_deposit,
      accReturn: i.acc_return,
      value: i.value,
      year: i.year
    }),
    filteredData
  );
};

const mapServerDataToSavingPlanChartData = (
  timeHorizon,
  currentDate,
  firstDeposit,
  forecast
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const filteredData = filterExpectedValueServerData(
    timeHorizon,
    {
      currentYear,
      currentMonth
    },
    firstDeposit,
    forecast
  );

  return R.map(
    i => ({
      x: Date.UTC(i.year, i.month),
      y: i.value ?? 0,
      custom: { accReturn: i.acc_return }
    }),
    filteredData
  );
};

const mapServerDataToSavingInBankAccountChartData = (
  timeHorizon,
  currentDate,
  firstDeposit,
  forecast
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const filteredData = filterExpectedValueServerData(
    timeHorizon,
    {
      currentYear,
      currentMonth
    },
    firstDeposit,
    forecast
  );

  return R.map(
    i => ({
      x: Date.UTC(i.year, i.month),
      y: i.value_bank ?? 0,
      custom: { accReturn: i.acc_return_bank }
    }),
    filteredData
  );
};
