import axios, { CancelTokenSource } from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { form as roboAdviceForm } from '../../../form/services/form';
import { getENINCompanyDetails } from 'features/roboAdvice/shared/api';
import {
  getFullCompanyOrgNumber,
  isVatNumberValid
} from 'features/roboAdvice/shared/helpers/enin';
import { SupportedCountryCode as SupportedCountryCodeType } from 'features/roboAdvice/shared/types';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useInitCompanyAccountingFigures() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const { values } = roboAdviceForm.getState();
  const orgNumber = values?.clientInformation?.orgNumber || null;
  const currentCountryCode =
    values.clientInformation?.country?.toLowerCase() || '';
  const isFirstCall = !values.companyFinancialSituation?.eninUpdateDate;

  const isOrgNumberValid = isVatNumberValid(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const fullOrgNumber = getFullCompanyOrgNumber(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const initCompanyAccountingFigures = async (isForcedCall = false) => {
    if (!isForcedCall && !isFirstCall) {
      return;
    }

    if (!isOrgNumberValid) {
      return;
    }

    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const companyDetails = await getENINCompanyDetails(
        accessToken,
        cancelTokenSource.token,
        fullOrgNumber
      );

      const {
        data: { accounting_numbers, income_statement }
      } = companyDetails;

      const {
        revenues = '',
        costs = '',
        sumAssets = '',
        operationalResult = '',
        preTaxResult = '',
        yearlyResult = '',
        sumEquity = '',
        sumDebt = '',
        netIncome = '',
        ordinaryResult = '',
        financeCost = '',
        currentAssets = '',
        accountingYear = ''
      } = mapServerENINCompanyDetails(
        accounting_numbers[0],
        income_statement[0]
      );

      roboAdviceForm.change<any>('companyFinancialSituation', {
        ...values.companyFinancialSituation,
        revenues: formatInputValue(revenues),
        costs: formatInputValue(costs),
        sumAssets: formatInputValue(sumAssets),
        operationalResult: formatInputValue(operationalResult),
        preTaxResult: formatInputValue(preTaxResult),
        yearlyResult: formatInputValue(yearlyResult),
        sumEquity: formatInputValue(sumEquity),
        sumDebt: formatInputValue(sumDebt),
        netIncome: formatInputValue(netIncome),
        financeCost: formatInputValue(financeCost),
        currentAssets: formatInputValue(currentAssets),
        ordinaryResult: formatInputValue(ordinaryResult),
        eninUpdateDate: new Date(),
        accountingYear: accountingYear
      });

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.financialSituation.eninDataLoadSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.financialSituation.readENINDataErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return initCompanyAccountingFigures;
}

const mapServerENINCompanyDetails = (
  {
    income_statement__operating_revenue,
    balance__total_assets,
    income_statement__ebit,
    balance__equity,
    balance__liabilities,
    income_statement__net_income,
    income_statement__ordinary_result_after_taxes,
    income_statement__ebt,
    balance__total_current_assets
  },
  {
    accounting_year,
    total_operating_expenses,
    ordinary_result,
    total_fin_expenses
  }
) => ({
  revenues: income_statement__operating_revenue,
  costs: total_operating_expenses,
  operationalResult: income_statement__ebit,
  preTaxResult: income_statement__ebt,
  yearlyResult: income_statement__ordinary_result_after_taxes,
  sumEquity: balance__equity,
  sumDebt: balance__liabilities,
  sumAssets: balance__total_assets,
  netIncome: income_statement__net_income,
  ordinaryResult: ordinary_result,
  financeCost: total_fin_expenses,
  currentAssets: balance__total_current_assets,
  accountingYear: accounting_year
});

const formatInputValue = (value: string) => [
  {
    value: value,
    toAdvisory: value,
    id: v4()
  },
  {
    id: v4()
  }
];
