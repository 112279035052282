import classNames from 'classnames';
import { DetailedHTMLProps } from 'react';

import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

type SvgProps = {
  className?: string;
  color?: string;
  width?: number | string;
  height?: number | string;
  opacity?: number | string;
};

const Unchecked = ({ className, color, width, height, opacity }: SvgProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <g>
        <g>
          <path
            fill={color}
            opacity={opacity}
            d="M18 2v14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h14c1.1 0 2 .9 2 2zm-2 0H2v14h14V2" // TODO: manualy recalculate values and change to fit 0 0 20 20
          />
        </g>
      </g>
    </svg>
  );
};

const Checked = ({ className, color, width, height, opacity }: SvgProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <g>
        <g>
          <path
            fill={color}
            opacity={opacity}
            d="M7 14L2 9l1.41-1.41L7 11.17l7.59-7.59L16 5zm9-14H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" // TODO: manualy recalculate values and change to fit 0 0 20 20
          />
        </g>
      </g>
    </svg>
  );
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    lineHeight: '0'
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    opacity: 0,
    cursor: 'pointer',

    '&:disabled': {
      cursor: 'default'
    }
  },
  checked: {
    userSelect: 'none'
  },
  unchecked: {
    userSelect: 'none'
  }
});

type Props = {
  className?: string;
  checkedColor?: string;
  uncheckedColor?: string;
} & DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Checkbox = ({
  checked,
  onChange,
  onClick,
  className,
  checkedColor,
  uncheckedColor,
  disabled,
  width = 15,
  height = 15,
  ...restProps
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classNames(classes.container, className)}>
      {checked && (
        <Checked
          className={classes.checked}
          opacity={disabled ? '0.5' : '1'}
          color={
            disabled
              ? theme.accentColor
              : checkedColor
              ? checkedColor
              : theme.accentColor
          }
          width={width}
          height={height}
        />
      )}
      {!checked && (
        <Unchecked
          className={classes.unchecked}
          opacity={disabled ? '0.5' : '1'}
          color={
            disabled
              ? theme.accentColor
              : uncheckedColor
              ? uncheckedColor
              : theme.accentColor
          }
          width={width}
          height={height}
        />
      )}
      <input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        className={classes.input}
        style={{
          width,
          height
        }}
        {...restProps}
      />
    </div>
  );
};

export default Checkbox;
