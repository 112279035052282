import { isNil } from 'ramda';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useErrorsStore } from '../../../../sharedModules/errors/services/errorsStore';
import { RoboAdviceAdviceSessionPages } from '../../../shared/constants';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import ActionButtons from '../../shared/components/actionButtons';
import AmountForAdviceSwitch from './amountForAdvice/switch';
import AmountForAdviceTable from './amountForAdvice/table/table';
import CompanyAccountingFigures from './companyAccountingFigures/index';
import CompanyDebt from './companyDebt';
import CompanyInvestableAssets from './companyInvestableAssets';
import CompanyLiquidity from './companyLiquidity';
import FollowUpTable from './followUpTable';
import PersonAssets from './personAssets';
import PersonDebt from './personDebt';
import PersonLiquidity from './personLiquidity';
import {
  useForm as useRoboAdviceForm,
  form as roboAdviceForm
} from 'features/roboAdvice/adviceSession/form/services/form';
import { useIsFollowUpEnabled } from 'features/roboAdvice/shared/components/useIsFollowUpEnabled';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useGetAllAdviceSessionErrors } from 'features/sharedModules/errors/services/useGetAllAdviceSessionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: Spacing.spacing02,
    marginBottom: Spacing.spacing04
  },
  amountForAdviceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `${Spacing.spacing04}px 0 ${Spacing.spacing01}px`,
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
    row: {
      flexDirection: 'column',
      marginBottom: Spacing.spacing02
    }
  }
});

const Page = () => {
  const { clientType } = useParams<AdviceSessionParams>();
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const { values } = useRoboAdviceForm();
  const {
    roboAdviceForm: {
      financialSituation: {
        accountingFigures,
        isAmountForAdviceEnabled,
        assets: { company: companyAssets, person: personAssets }
      }
    },
    advisoryComponents: {
      financialSituation: {
        amountForAdvice: { advisorNotes: amountForAdviceAdvisorNotesConfig }
      }
    }
  } = useCustomerConfig();
  const allErrors = useGetAllAdviceSessionErrors();
  const errors = allErrors[RoboAdviceAdviceSessionPages.financialSituation];
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const sessionStore = useSessionStore();
  const { isFollowUpEnabled } = useIsFollowUpEnabled();

  useEffect(() => {
    if (isErrorModeEnabled) {
      roboAdviceForm.submit();
    }
  }, []);

  const isAmountForAdviceVisible =
    isAmountForAdviceEnabled &&
    (clientType === ClientTypes.person ? personAssets : companyAssets).some(
      ({ showInAmountForAdvice, id }) =>
        showInAmountForAdvice &&
        (clientType === ClientTypes.person
          ? !isNil(values.personFinancialSituation?.[id]?.[0]?.value)
          : !isNil(values.companyFinancialSituation?.[id]?.[0]?.value))
    );

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-id-${RoboAdviceAdviceSessionPages.financialSituation}`}
      id={`panel-id-${RoboAdviceAdviceSessionPages.financialSituation}`}
    >
      <ErrorsList
        pageName={i18n('roboAdvice.tabs.financialSituation')}
        errors={errors}
      />
      {clientType === ClientTypes.person && (
        <>
          <div className={classes.row}>
            <PersonAssets
              hasError={!!errors?.assets?.length && isErrorModeEnabled}
              errors={errors.assets}
            />

            <PersonDebt
              hasError={!!errors?.debt?.length && isErrorModeEnabled}
              errors={errors.debt}
            />
          </div>
          <div className={classes.row}>
            <PersonLiquidity
              hasError={!!errors?.liquidity?.length && isErrorModeEnabled}
              errors={errors.liquidity}
            />
          </div>
        </>
      )}

      {clientType === ClientTypes.company && (
        <>
          <div className={classes.row}>
            <CompanyInvestableAssets
              hasError={!!errors?.assets?.length && isErrorModeEnabled}
              errors={errors.assets}
            />

            <CompanyDebt
              hasError={!!errors?.debt?.length && isErrorModeEnabled}
              errors={errors.debt}
            />
          </div>
          <div className={classes.row}>
            <CompanyLiquidity
              hasError={!!errors?.liquidity?.length && isErrorModeEnabled}
              errors={errors.liquidity}
            />

            {accountingFigures.length > 0 && (
              <CompanyAccountingFigures
                hasError={
                  !!errors?.accountingFigures?.length && isErrorModeEnabled
                }
                errors={errors.accountingFigures}
              />
            )}
          </div>
        </>
      )}

      {sessionStore.followUpId && isFollowUpEnabled && (
        <FollowUpTable
          errors={isErrorModeEnabled ? errors.followUpTable : []}
        />
      )}

      {isAmountForAdviceVisible &&
        (!sessionStore.followUpId || !isFollowUpEnabled) && (
          <>
            <div
              className={classes.amountForAdviceContainer}
              id={'amountForAdviceContainer'}
            >
              <div>{i18n('roboAdvice.financialSituation.amountForAdvice')}</div>

              <AmountForAdviceSwitch clientType={clientType} />
            </div>

            <AmountForAdviceTable errors={errors.amountForAdvice} />

            {amountForAdviceAdvisorNotesConfig.enabled && (
              <AdvisorNotes
                className={classes.advisorNotes}
                header={i18n(
                  'financialSituation.amountForAdvice.advisorNotes.header'
                )}
                customPlaceholder={i18n(
                  'financialSituation.amountForAdvice.advisorNotes.placeholder'
                )}
                infoTooltipText={i18n(
                  'financialSituation.amountForAdvice.advisorNotes.infoTooltip'
                )}
                disabled={isSessionReadOnly}
                required={amountForAdviceAdvisorNotesConfig.required}
                name="amountForAdviceAdvisorNotes"
                id="amount-for-advice-advisor-notes"
                errors={errors?.amountForAdviceAdvisoryNotes}
              />
            )}
          </>
        )}

      <AdvisorNotes
        className={classes.advisorNotes}
        header={i18n('financialSituation.advisorNotes.header')}
        customPlaceholder={i18n('financialSituation.advisorNotes.placeholder')}
        infoTooltipText={i18n('financialSituation.advisorNotes.infoTooltip')}
        disabled={isSessionReadOnly}
        name={'financialSituationAdvisorNotes'}
      />
      <NextButtonError allErrors={allErrors} />
      <ActionButtons pageId={RoboAdviceAdviceSessionPages.financialSituation} />
    </div>
  );
};

export default Page;
