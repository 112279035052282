import { useForm as useRoboAdviceForm } from '../../../roboAdvice/adviceSession/form/services/form';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, InstrumentsErrors } from '../types';

export const useGetInstrumentsErrors = (): InstrumentsErrors => {
  const i18n = useI18n();
  const roboAdviceFormState = useRoboAdviceForm();
  const {
    roboAdviceForm: { errorMessagesEnabled }
  } = useCustomerConfig();

  return {
    instruments: getInstrumentsErrors({
      roboAdviceFormState,
      i18n,
      errorMessagesEnabled
    }),
    advisorNotes: getAdvisorNotesErrors({
      roboAdviceFormState,
      i18n,
      errorMessagesEnabled
    })
  };
};

const getInstrumentsErrors = ({
  roboAdviceFormState,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const instrumentsErrors: ErrorMessage[] = [];
  if (errorMessagesEnabled) {
    if (!roboAdviceFormState.values?.instrumentsSelected?.length) {
      instrumentsErrors.push({
        alertMessage: i18n('roboAdvice.errors.emptyInstrumentsTableAlert')
          .replace('{0}', i18n('roboAdvice.orderExecution.monthlyDeposit'))
          .replace('{1}', i18n('roboAdvice.orderExecution.initialDeposit')),
        listMessage: i18n('roboAdvice.errors.emptyInstrumentsTable'),
        elementId: 'instruments-table'
      });
    } else if (
      roboAdviceFormState.values?.instrumentsSelected?.some(
        instrument => !instrument.initialDeposit && !instrument.monthlySaving
      )
    ) {
      instrumentsErrors.push({
        alertMessage: i18n('roboAdvice.errors.noInstrumentsAlert')
          .replace('{0}', i18n('roboAdvice.orderExecution.monthlyDeposit'))
          .replace('{1}', i18n('roboAdvice.orderExecution.initialDeposit')),
        listMessage: i18n('roboAdvice.errors.noInstruments')
          .replace('{0}', i18n('roboAdvice.orderExecution.monthlyDeposit'))
          .replace('{1}', i18n('roboAdvice.orderExecution.initialDeposit')),
        elementId: 'instruments-table'
      });
    }
  }

  return instrumentsErrors;
};

const getAdvisorNotesErrors = ({
  roboAdviceFormState,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const advisorNotesErrors: ErrorMessage[] = [];
  if (errorMessagesEnabled) {
    if (!roboAdviceFormState.values?.instrumentsAdvisorNotes) {
      advisorNotesErrors.push({
        alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0} ',
          ''
        ),
        elementId: 'instruments-advisor-notes'
      });
    } else if (roboAdviceFormState.values.instrumentsAdvisorNotes.length < 25) {
      advisorNotesErrors.push({
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0} ',
          ''
        ),
        alertMessage: i18n(
          'roboAdvice.advisory.advisorNotes.advisorNotesMinLengthValidationError'
        ).replace('{0}', '25'),
        elementId: 'instruments-advisor-notes'
      });
    }
  }
  return advisorNotesErrors;
};
