import { OrderSummaryData } from '../../../roboAdvice/adviceSession/orderSummary';
import MonthlyOrders from './monthlyOrders';
import OneTimeOrders from './oneTimeOrders';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { CustomAttributeFromConfig } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  container: {
    marginLeft: 36
  }
});

export type Order = {
  moneyAmount: number;
  id: string;
  isin: string;
  name: string;
  FundStandardName: string;
};

type Props = {
  orderSummaryData: OrderSummaryData[];
  selectedGoalsIds: string[];
  cultureCode: string;
  productAttributes?: CustomAttributeFromConfig[] | null;
  isLoading?: boolean;
  isError?: boolean;
  isPercentColumnEnabled?: boolean;
};

const OrderSummaryVisual = ({
  orderSummaryData,
  selectedGoalsIds,
  cultureCode,
  productAttributes = null,
  isLoading = false,
  isError = false,
  isPercentColumnEnabled = false
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const selectedGoalsTotalFirstDeposit = useGoalsStore(
    selectors.getSelectedGoalsTotalFirstDeposit
  );
  const selectedGoalsTotalMonthlyDeposit = useGoalsStore(
    selectors.getSelectedGoalsTotalMonthlyDeposit
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <div>{i18n('roboAdvice.proposal.orderSummary.noData')}</div>;
  }

  return (
    <div className={classes.container}>
      {!!selectedGoalsTotalFirstDeposit && (
        <OneTimeOrders
          cultureCode={cultureCode}
          orderSummaryData={orderSummaryData}
          productAttributes={productAttributes}
          selectedGoalsIds={selectedGoalsIds}
          isPercentColumnEnabled={isPercentColumnEnabled}
        />
      )}

      {!!selectedGoalsTotalMonthlyDeposit && (
        <MonthlyOrders
          cultureCode={cultureCode}
          orderSummaryData={orderSummaryData}
          productAttributes={productAttributes}
          selectedGoalsIds={selectedGoalsIds}
          isPercentColumnEnabled={isPercentColumnEnabled}
        />
      )}
    </div>
  );
};

export default OrderSummaryVisual;
