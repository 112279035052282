import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { useTotalValues } from '../../services/selectors';
import TableBodyRow from 'features/shared/components/table/tableBodyRow';
import { FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  numberCell: {
    textAlign: 'right',
    maxWidth: '16rem'
  },
  summaryRowCell: {
    background: theme.inputFillFocusColor,
    padding: '1.6rem 1.3rem',
    fontWeight: FontWeights.medium
  }
}));

type Props = {
  numberOfAdditionalColumns: number;
  showISINColumn: boolean;
  showAssetClassColumn: boolean;
};

const SummaryRow = ({
  numberOfAdditionalColumns,
  showISINColumn,
  showAssetClassColumn
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const totalValues = useTotalValues();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );

  return (
    <AnyTableBodyRow data-testid={'summary-row'}>
      {() => (
        <>
          <td className={classes.summaryRowCell}>
            {i18n('roboAdvice.orderExecution.instrumentsList.summaryRow')}
          </td>
          {showISINColumn && <td className={classes.summaryRowCell} />}
          {showAssetClassColumn && <td className={classes.summaryRowCell} />}

          {[...Array(numberOfAdditionalColumns)].map((_, index) => (
            <td className={classes.summaryRowCell} key={index} />
          ))}

          <td
            className={classNames(classes.summaryRowCell, classes.numberCell)}
          >
            {formatNumber(cultureCode, totalValues.initialDeposit, 0, 2)}
          </td>

          <td
            className={classNames(classes.summaryRowCell, classes.numberCell)}
          >
            {formatNumber(cultureCode, totalValues.monthlySaving, 0, 2)}
          </td>

          <td className={classes.summaryRowCell} />
        </>
      )}
    </AnyTableBodyRow>
  );
};

export default SummaryRow;
