import axios from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentDate } from '../../../../shared/utils';
import { useExpectedPathChartStore } from '../../../expectedPathChart';
import { createAnalyzeForecast } from '../../../shared/api';
import { DEFAULT_CONFIDENCE } from '../../constants';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import {
  getHorizonAnalysisProjectionYearsValue,
  getIsFormComplete
} from '../../services/selectors.js';
import { HorizonConfig } from 'defaults/defaultTimeHorizonConfig';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadExpectedPathChartData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const { timeHorizonConfig }: { timeHorizonConfig: HorizonConfig } =
    useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<any>();
  const readExpectedPathChartData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalPageStore = useProposalPageStore.getState();
    const expectedPathChartStore = useExpectedPathChartStore.getState();
    const { goals: storeGoals } = useGoalsStore.getState();

    const isFormComplete = getIsFormComplete();
    if (!isFormComplete) {
      expectedPathChartStore.resetChartData();
      proposalPageStore.setPageStatus(
        'readExpectedPathChartData',
        PageStatuses.failed
      );

      return;
    }

    try {
      proposalPageStore.setPageStatus(
        'readExpectedPathChartData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();

      const isUpdated =
        !!proposalPageStore.goalsToUpdate.readExpectedPathChartData.length;

      const goals = isUpdated
        ? proposalPageStore.goalsToUpdate.readExpectedPathChartData
        : storeGoals;

      for (const goal of goals) {
        const portfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        const timeHorizon = getHorizonAnalysisProjectionYearsValue({
          timeHorizonConfig,
          timeHorizon: goal.data.timeHorizon
        });

        const createAnalyzeForecastResponse = await createAnalyzeForecast(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            confidence_interval: DEFAULT_CONFIDENCE,
            initial_deposit: goal.data.firstDeposit,
            monthly_saving: goal.data.monthlyDeposit,
            portfolio,
            time_horizon: timeHorizon
          },
          {
            namespace_id: goal.data.productPlatformNamespace
          }
        );

        const data = {
          forecast: createAnalyzeForecastResponse.data.forecast,
          timeHorizon,
          currentDate,
          goalId: goal.goalId
        };

        isUpdated
          ? expectedPathChartStore.editGoalData(data)
          : expectedPathChartStore.addGoalData(data);
      }

      if (isUpdated) {
        proposalPageStore.setGoalsToUpdate('readExpectedPathChartData', []);
      }

      proposalPageStore.setPageStatus(
        'readExpectedPathChartData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        expectedPathChartStore.resetChartData();
        proposalPageStore.setPageStatus(
          'readExpectedPathChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readExpectedPathChartData;
}
