import axios, { CancelToken } from 'axios';

import {
  CreateInvestorRequestData,
  CreateInvestorResponse,
  GetInvestorInformationResponse,
  UpdateInvestorInformationRequestData,
  UpdateInvestorResponse,
  GetClientSessionsParams,
  GetClientSessionsResponse,
  CreateAdviceSessionRequestData,
  CreateAdviceSessionResponse,
  DeleteAdviceSessionResponse,
  UpdateAdviceSessionRequestData,
  UpdateAdviceSessionResponse
} from './types';
import { stateApi } from 'features/shared/utils/axios';

export const getClientSessions = (
  accessToken: string,
  cancelToken: CancelToken,
  params: GetClientSessionsParams
) =>
  stateApi.get<GetClientSessionsResponse>('/session', {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });

export const createAdviceSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: CreateAdviceSessionRequestData
) =>
  stateApi.post<CreateAdviceSessionResponse>('/session', data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const deleteAdviceSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId: string
) =>
  stateApi.delete<DeleteAdviceSessionResponse>(`/session/${sessionId}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const updateAdviceSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId: string,
  data: UpdateAdviceSessionRequestData
) =>
  stateApi.patch<UpdateAdviceSessionResponse>(`/session/${sessionId}`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const createInvestor = (
  accessToken: string,
  data: CreateInvestorRequestData
) =>
  stateApi.post<CreateInvestorResponse>('/investor', data, {
    headers: { Authorization: accessToken }
  });

export const getInvestorInformation = (
  accessToken: string,
  cancelToken: CancelToken,
  investorId: string
) =>
  stateApi.get<GetInvestorInformationResponse>(`/investor/${investorId}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const updateInvestorInformation = (
  accessToken: string,
  data: UpdateInvestorInformationRequestData,
  investorId: string
) =>
  stateApi.patch<UpdateInvestorResponse>(`/investor/${investorId}`, data, {
    headers: { Authorization: accessToken }
  });

export const getCompanyData = (orgNumber: string, cancelToken: CancelToken) =>
  axios.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`, {
    cancelToken
  });
