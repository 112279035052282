import { useField } from './useField';
import Checkbox from 'features/shared/components/checkbox/index';

const FinalFormCheckbox = ({ name, fieldConfig, ...restProps }) => {
  const {
    input: { checked, onChange, onFocus, onBlur },
    meta: { error }
  } = useField(name, { ...fieldConfig, type: 'checkbox' });

  return (
    <Checkbox
      checked={checked}
      onChange={e => onChange(!checked)}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      {...restProps}
    />
  );
};

export default FinalFormCheckbox;
