import classNames from 'classnames';
import { useState } from 'react';

import Table from '../../../../shared/components/table/table';
import TableBody from '../../../../shared/components/table/tableBody';
import TableBodyRow from '../../../../shared/components/table/tableBodyRow';
import { getTranslation } from '../../../../shared/utils/translations';
import FinalFormRadio from '../../../../sharedModules/finalForm/components/radio';
import { createUseStyles } from '../../../../sharedModules/styles/components/styles';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { advancedSuitabilityTablesNames } from '../constants';
import TrainingModal from './trainingModal';
import { ReactComponent as TrainingIcon } from 'assets/training.svg';
import { AdvancedSuitabilityTrainingContent } from 'defaults/defaultRoboAdviceForm';
import {
  form as roboAdviceForm,
  useForm as useRoboAdviceForm
} from 'features/roboAdvice/adviceSession/form/services/form';
import Icon from 'features/shared/components/icon';
import Tooltip from 'features/shared/components/tooltip/index';
import { Typography } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';

const AnyTableComponent: any = Table;

const useStyles = createUseStyles(theme => ({
  container: {
    maxWidth: '1066px'
  },
  headerCell: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    height: '60px',
    color: theme.secondaryColor,

    '&:not(:first-child)': {
      textAlign: 'center'
    }
  },
  bodyCell: {
    lineHeight: Typography.body1.lineHeight,

    '&:not(:first-child)': {
      textAlign: 'center'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12
  },
  infoIcon: {
    color: theme.secondaryColor,
    cursor: 'pointer',
    fontSize: 20
  },
  active: {
    color: theme.accentColor
  },
  list: {
    margin: 0,
    paddingLeft: 18
  },
  trainingIcon: {
    cursor: 'pointer',
    fill: '#fff'
  },
  trainingCompleted: {
    fill: theme.successColor
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  }
}));

const AdvancedSuitabilityTable = ({
  name,
  options,
  checks,
  header,
  errors
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    values: { checksTraining }
  } = useRoboAdviceForm();
  const {
    roboAdviceForm: {
      knowledgeAndExperience: {
        advancedSuitability: {
          showTraining,
          trainingContent,
          checksTranslations
        }
      }
    }
  } = useCustomerConfig();
  const [selectedCheck, setSelectedCheck] = useState<
    AdvancedSuitabilityTrainingContent | undefined | null
  >(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [openTooltipName, setOpenTooltipName] = useState(null);
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);

  const openTrainingModal = clickedCheck => {
    setSelectedCheck(
      trainingContent?.find(({ check }) => check === clickedCheck)
    );
  };

  const completeTraining = check => {
    roboAdviceForm.change('checksTraining', {
      ...checksTraining,
      [check]: true
    });
    setSelectedCheck(null);
  };

  const tableHeaders = [{ title: '' }, ...options].map(
    ({ id, label, description, descriptionSubsections }) => ({
      id,
      component: (
        <div className={classes.header}>
          <span>{getTranslation(label)}</span>

          {(description || descriptionSubsections) && (
            <div>
              <Tooltip
                open={isTooltipOpen && openTooltipName === name + id}
                onOpenChange={setIsTooltipOpen}
                content={
                  <>
                    {description && <div>{getTranslation(description)}</div>}

                    {descriptionSubsections?.length > 0 && (
                      <ul className={classes.list}>
                        {descriptionSubsections.map(
                          (descriptionSubsection, index) => (
                            <li key={index}>
                              {getTranslation(descriptionSubsection)}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </>
                }
                trigger={
                  <Icon
                    className={classNames(classes.infoIcon, {
                      [classes.active]:
                        isTooltipOpen && openTooltipName === name + id
                    })}
                    type="info"
                  />
                }
                triggerOnClick={() => {
                  setOpenTooltipName(name + id);
                  setIsTooltipOpen(isOpen => !isOpen);
                }}
              />
            </div>
          )}
        </div>
      ),
      className: classes.headerCell
    })
  );

  return (
    <>
      {name === advancedSuitabilityTablesNames.knowledge && showTraining && (
        <TrainingModal
          trainingContent={selectedCheck}
          isOpen={!!selectedCheck}
          closeHandler={() => setSelectedCheck(null)}
          completeTraining={completeTraining}
          header={`${header} - ${selectedCheck?.check}`}
        />
      )}

      <AnyTableComponent
        externalClasses={{
          container: classNames(classes.container, {
            [classes.hasError]: errors?.length && isErrorModeEnabled
          })
        }}
        header={
          name === advancedSuitabilityTablesNames.knowledge && showTraining
            ? [
                ...tableHeaders,
                {
                  title: i18n(
                    'roboAdvice.knowledgeAndExperience.advancedThemeSuitabilityAssessment.training'
                  ),
                  className: classes.headerCell
                }
              ]
            : tableHeaders
        }
      >
        <TableBody cellsNumber={undefined} noDataTitle={undefined}>
          {checks?.map((check, index) => (
            // @ts-ignore: Used because TableBodyRow isn't written in TypeScript
            <TableBodyRow isCssParity={false} index={index} key={index}>
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell
                    )}
                  >
                    {getTranslation(
                      checksTranslations.find(({ id }) => id === check)?.label
                    ) || check}
                  </td>

                  {options?.map(({ id: optionId }) => (
                    <td
                      key={optionId}
                      className={classNames(
                        bodyRowCellClassName,
                        classes.bodyCell
                      )}
                    >
                      {
                        <FinalFormRadio
                          name={`suitability.${name}.${check}`}
                          activeValue={optionId}
                          disabled={isSessionReadOnly}
                        />
                      }
                    </td>
                  ))}

                  {name === advancedSuitabilityTablesNames.knowledge &&
                    showTraining && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.bodyCell
                        )}
                      >
                        <TrainingIcon
                          className={classNames(classes.trainingIcon, {
                            [classes.trainingCompleted]: checksTraining?.[check]
                          })}
                          onClick={() => openTrainingModal(check)}
                        />
                      </td>
                    )}
                </>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </AnyTableComponent>
    </>
  );
};

export default AdvancedSuitabilityTable;
