import { ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import * as R from 'ramda';

import { AdviceInformationField } from '../../proposal/services/mappingTypes';
import { CustomPortfolioTabsNames } from '../../shared/constants';
import { Goal, useGoalsStore } from '../../shared/services/goalsStore';
import { isFieldProgressCalculationRequired } from '../../shared/services/progressSelectors';
import { getAlignmentCriteriaFields } from '../../sustainability/components/utils';
import store from 'features/main/app/store.js';
import { getDetalizedNumberInputErrors } from 'features/roboAdvice/adviceSession/detalizedNumberInput/services/selectors.js';
import {
  getDefaultErrorMessage,
  getFieldsWithTextFields
} from 'features/roboAdvice/shared/utils';
import { ClientTypes } from 'features/shared/constants/session';
import sessionSelectors from 'features/shared/services/session/selectors';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import {
  createForm as ffCreateForm,
  FormApi
} from 'features/sharedModules/finalForm/services/finalForm';
import {
  createUseFinalFormAdapter,
  FormState,
  UseFinalFormStore
} from 'features/sharedModules/zustand/components/finalFormAdapter';

const getFinancialSituationRequiredFieldsErrors = ({
  financialSituation,
  requiredFields,
  errors,
  clientTypeKey
}) => {
  requiredFields.forEach(({ id: key, type }) => {
    const value = financialSituation?.[key];

    if (Array.isArray(value) && R.isNil(value?.[0]?.value)) {
      errors = R.assocPath(
        [clientTypeKey, key, 0],
        {
          value:
            'roboAdvice.detalizedNumberInput.defaultValueEmptyValidationError'
        },
        errors
      );
    } else if (
      type !== FieldComponentTypes.detalizedNumberInput &&
      type !== undefined &&
      (R.isNil(value) || value === '')
    ) {
      errors = R.assocPath(
        [clientTypeKey, key],
        'roboAdvice.detalizedNumberInput.valueEmptyValidationError',
        errors
      );
    }
  });

  return errors;
};

export const getAssetsDebtValidationErrors = (
  fields,
  values,
  clientTypeKey: string,
  errors
) => {
  fields.forEach(field => {
    const detalizedErrors = getDetalizedNumberInputErrors(values?.[field]);

    detalizedErrors.forEach((error, index) => {
      if (error) {
        errors = R.assocPath([clientTypeKey, field, index], error, errors);
      }
    });
  });

  return errors;
};

export const getAdvisoryAdvisorNotesValidationError = (
  value: string | undefined
) => {
  const trimmedValue = R.isNil(value) ? null : R.trim(value);
  const minLength = 25;

  if (R.isNil(trimmedValue) || R.isEmpty(trimmedValue)) {
    return 'roboAdvice.advisory.advisorNotes.advisorNotesEmptyValidationError';
  } else if (trimmedValue.length < minLength) {
    return {
      text: 'roboAdvice.advisory.advisorNotes.advisorNotesMinLengthValidationError',
      args: [minLength]
    };
  }

  return undefined;
};

export const getKnowledgeAndExperienceQuestionsValidationErrors = ({
  values,
  errors,
  knowledgeAndExperienceQuestions
}) => {
  knowledgeAndExperienceQuestions?.forEach(({ name, correctAnswerId }) => {
    const value = R.path(name.split('.'), values);
    if (R.isNil(value)) {
      errors[name] =
        'roboAdvice.knowledgeAndExperience.pleaseAnswerTheQuestion';
    }

    if (correctAnswerId && value !== correctAnswerId) {
      errors[name] = 'roboAdvice.knowledgeAndExperience.quizError';
    }
  });

  return errors;
};

export const getExpectationOfRiskValidationError = value => {
  if (R.isNil(value)) {
    return 'roboAdvice.purposeAndRisk.expectationOfRiskEmptyValidationError';
  }

  return null;
};

export const getRiskStrategyValidationError = value => {
  if (R.isNil(value)) {
    return 'roboAdvice.purposeAndRisk.riskStrategyEmptyValidationError';
  }

  return null;
};

export const getFinancialSituationLiquidityErrors = ({
  financialSituation,
  errors
}) => {
  if (
    financialSituation?.expectingFinancialChanges &&
    (!financialSituation?.expectingFinancialChangesNote ||
      financialSituation?.expectingFinancialChangesNote.length === 0)
  ) {
    errors.expectingFinancialChangesNote =
      'roboAdvice.financialSituation.liquidity.error';
  }

  return errors;
};

export const getFinancialSituationValidationErrors = ({
  clientInformation,
  financialSituationConfig,
  financialSituation,
  errors
}) => {
  const { assets, debt, liquidity, accountingFigures } =
    financialSituationConfig;
  if (assets && debt && clientInformation?.clientType) {
    const clientTypeKey =
      clientInformation?.clientType === ClientTypes.company
        ? 'companyFinancialSituation'
        : 'personFinancialSituation';

    // One Field In Assets Required validation
    const assetsIds = assets[clientInformation?.clientType]
      .filter(({ enabled }) => enabled)
      .map(({ id }) => id);
    const haveAssetsAnyValues = assetsIds.some(
      id => !R.isNil(financialSituation?.[id]?.[0]?.value)
    );
    if (
      !haveAssetsAnyValues &&
      financialSituationConfig?.oneFieldInAssetsRequired
    ) {
      errors.assetsError = 'roboAdvice.financialSituation.assets.error';
    }

    // Assets details validation
    errors = getAssetsDebtValidationErrors(
      assetsIds,
      financialSituation,
      clientTypeKey,
      errors
    );

    // One Field In Debt Required validation
    const debtIds = debt[clientInformation?.clientType]
      .filter(({ enabled }) => enabled)
      .map(({ id }) => id);
    const hasDebtAnyValues = debtIds.some(
      id => !R.isNil(financialSituation?.[id]?.[0]?.value)
    );
    if (!hasDebtAnyValues && financialSituationConfig?.oneFieldInDebtRequired) {
      errors.debtError = 'roboAdvice.financialSituation.debt.error';
    }

    // Debt details validation
    errors = getAssetsDebtValidationErrors(
      debtIds,
      financialSituation,
      clientTypeKey,
      errors
    );

    // Required fields validation
    const allFields = [
      ...(assets[clientInformation?.clientType] || []),
      ...(debt[clientInformation?.clientType] || []),
      ...(liquidity[clientInformation?.clientType] || []),
      ...(accountingFigures || [])
    ];
    const requiredFields = allFields.filter(
      ({ enabled, required }) => enabled && required
    );
    errors = getFinancialSituationRequiredFieldsErrors({
      financialSituation,
      requiredFields,
      errors,
      clientTypeKey
    });

    // Liquidity validation
    errors = getFinancialSituationLiquidityErrors({
      financialSituation,
      errors
    });
  }

  return errors;
};

type AdvisorNotesErrors = {
  advisorNotes: {
    [id: string]: string;
  };
};

type AssetClassAllocationAdvisorNotesErrors = {
  assetClassAllocation: AdvisorNotesErrors;
};

type FundAllocationAdvisorNotesErrors = {
  fundAllocation: AdvisorNotesErrors;
};

type CustomPortfolioAdvisorNotesErrors = {
  customPortfolio:
    | AssetClassAllocationAdvisorNotesErrors
    | FundAllocationAdvisorNotesErrors;
};

export const getCustomPortfolioAdvisorNotesErrors = (
  goals: Goal[],
  values: AdviceSessionFormValues,
  tab: typeof CustomPortfolioTabsNames[keyof typeof CustomPortfolioTabsNames]
) => {
  return goals
    .filter(({ data }) => data.isPortfolioCustom)
    .reduce((acc, curr) => {
      const value = values.customPortfolio?.[tab]?.advisorNotes[curr.goalId];
      const trimmedValue = R.isNil(value) ? null : R.trim(value);

      if (R.isNil(trimmedValue) || R.isEmpty(trimmedValue)) {
        return {
          ...acc,
          customPortfolio: {
            ...acc.customPortfolio,
            [tab]: {
              ...acc.customPortfolio?.[tab],
              advisorNotes: {
                ...acc.customPortfolio?.[tab]?.advisorNotes,
                [curr.goalId]:
                  'roboAdvice.advisory.advisorNotes.advisorNotesEmptyValidationError'
              }
            }
          }
        };
      }

      return acc;
    }, {} as CustomPortfolioAdvisorNotesErrors);
};

export const getAdvisoryValidationErrors = (
  values: AdviceSessionFormValues,
  errors: ValidationErrors,
  isAssetClassAllocationAdvisorNotesEnabled: boolean,
  isAssetClassAllocationAdvisorNotesRequired: boolean,
  isFundAllocationAdvisorNotesEnabled: boolean,
  isFundAllocationAdvisorNotesRequired: boolean,
  goals: Goal[]
): ValidationErrors => {
  const { advisoryAdvisorNotes } = values;

  const assetClassAllocationAdvisorNotesErrors =
    isAssetClassAllocationAdvisorNotesEnabled &&
    isAssetClassAllocationAdvisorNotesRequired
      ? getCustomPortfolioAdvisorNotesErrors(
          goals,
          values,
          CustomPortfolioTabsNames.assetClassAllocation
        )
      : {};

  const fundAllocationAdvisorNotesErrors =
    isFundAllocationAdvisorNotesEnabled && isFundAllocationAdvisorNotesRequired
      ? getCustomPortfolioAdvisorNotesErrors(
          goals,
          values,
          CustomPortfolioTabsNames.fundAllocation
        )
      : {};

  const customPortfolioAdvisorNotesErrors = R.mergeDeepRight(
    assetClassAllocationAdvisorNotesErrors,
    fundAllocationAdvisorNotesErrors
  );

  return {
    ...errors,
    advisoryAdvisorNotes:
      getAdvisoryAdvisorNotesValidationError(advisoryAdvisorNotes),
    ...customPortfolioAdvisorNotesErrors
  };
};

const getCustomFieldsErrors = ({ fields, values, errors }) => {
  if (fields) {
    const items = fields.filter(
      f =>
        f.required &&
        (R.isNil(f.when) || isFieldProgressCalculationRequired(f.when, values))
    );

    const fieldsWithTextFields = getFieldsWithTextFields(fields, values) || [];

    const allFields = [...items, ...fieldsWithTextFields];

    return allFields.reduce((acc, curr) => {
      const path = curr.name.split('.');
      const value = R.path(path, values);
      if (R.isNil(value) || value === '') {
        return R.set(
          R.lensPath(path),
          getDefaultErrorMessage(curr.componentType, curr.additionalTextField),
          acc
        );
      }
      return acc;
    }, errors);
  }

  return errors;
};

const getSustainabilityErrors = ({ values, errors, customerConfig }) => {
  const {
    enabled: isSustainabilityEnabled,
    genericAssessment: {
      enabled: isGenericAssessmentEnabled,
      isCommentFieldRequired: isGenericAssessmentCommentFieldRequired,
      isCommentFieldShown: isGenericAssessmentCommentFieldShown
    } = {
      enabled: undefined,
      isCommentFieldRequired: undefined,
      isCommentFieldShown: undefined
    }
  } = customerConfig?.roboAdvice?.sustainability;

  if (!isSustainabilityEnabled) {
    return errors;
  }

  if (
    isGenericAssessmentEnabled &&
    isGenericAssessmentCommentFieldShown &&
    isGenericAssessmentCommentFieldRequired &&
    !values?.sustainability?.genericAssessment?.comment?.length
  ) {
    errors = R.set(
      R.lensPath(['sustainability', 'genericAssessment', 'comment']),
      'roboAdvice.sustainability.alignmentError',
      errors
    );
  }
  if (
    isGenericAssessmentEnabled &&
    values.sustainability?.genericAssessment?.answer === false
  ) {
    return errors;
  }

  const alignmentCriteriaFields = getAlignmentCriteriaFields({
    customerConfig
  }).map(a => ({
    name: a.name,
    required: a.config.required
  }));

  alignmentCriteriaFields.forEach(field => {
    const path = field.name.split('.');
    const alignmentValue = R.view(R.lensPath(path), values);

    if (R.isNil(alignmentValue) && field.required) {
      errors = R.set(
        R.lensPath(path),
        'roboAdvice.sustainability.alignmentError',
        errors
      );
    }
  });

  return errors;
};

const getOrderInformationPage2Errors = ({ sections, values, errors }) => {
  const fields = sections
    ?.filter(({ isEnabled }) => isEnabled)
    .map(({ items }) => items)
    .flat();
  errors = getCustomFieldsErrors({ fields, values, errors });
  return errors;
};

export type Sustainability = {
  alignmentCriteria?: Record<string, number>;
  alignmentCriteriaTextFields?: Record<string, string>;
  alignmentCriteriaValueAdapted?: {
    [name: string]: {
      isValueAdaptedAnswer?: boolean;
      isValueAdaptedQuestionVisible?: boolean;
    };
  };
  preferenceCriteria?: string[];
  preferenceCriteriaAdvisorNotes?: string;
  preferenceCriteriaValueAdapted?: {
    [name: string]: {
      isValueAdaptedAnswer?: boolean;
      isValueAdaptedQuestionVisible?: boolean;
    };
  };
  arePreferenceCriteriaValueAdaptedQuestionsVisible?: boolean;
  genericAssessment?: {
    answer?: boolean;
    comment?: string;
    isValueAdaptedAnswer?: boolean;
    isValueAdaptedQuestionVisible?: boolean;
  };
  offModelQuestions?: Record<string, string | number>;
  offModelQuestionsAdvisorNotes?: Record<string, string>;
  offModelQuestionsValueAdapted?: {
    [name: string]: {
      isValueAdaptedAnswer?: boolean;
      isValueAdaptedQuestionVisible?: boolean;
    };
  };
};

export type Suitability = {
  [key: string]: {
    [key: string]: number;
  };
};

export type InstrumentSelected = {
  initialDeposit: number | undefined;
  monthlySaving: number | undefined;
  isin?: string;
  name: string;
  id: string;
  subAssetClass: string;
};

export type AdviceSessionFormValues = {
  clientInformation?: any;
  adviceInformation?: AdviceInformationField[];
  adviceInformationAdvisorNotes?: string;
  expectationOfRisk?: any;
  riskStrategy?: any;
  purposeAndRiskAdvisorNotes?: string;
  dropInStd?: any;
  dropInPercent?: any;
  educationWork?: any;
  experience?: any;
  quiz1?: any;
  quiz1Date?: string;
  quiz2?: any;
  quiz2Date?: string;
  quiz3?: any;
  quiz3Date?: string;
  knowledgeAndExperienceAdvisorNotes?: string;
  personFinancialSituation?: any;
  companyFinancialSituation?: any;
  financialSituationAdvisorNotes?: string;
  advisoryAdvisorNotes?: string;
  proposalAdvisorNotes?: string;
  checksTraining?: { [key: string]: boolean };
  suitability?: Suitability;
  sustainability?: Sustainability;
  customPortfolio?: {
    assetClassAllocation: { advisorNotes: Record<string, string> };
    fundAllocation: { advisorNotes: Record<string, string> };
  };
  instrumentsSelected?: InstrumentSelected[];
  instrumentsAdvisorNotes?: string;
  orderInformationAdvisorNotes?: string;
  orderInformation?: Record<string, any>;
};

export type AdviceSessionFormState = FormState<AdviceSessionFormValues>;

export type AdviceSessionForm = FormApi<AdviceSessionFormValues>;

export const form = ffCreateForm<AdviceSessionFormValues>({
  onSubmit: () => {},
  validationDependencies: {
    customerConfig: [
      () => {
        const customerConfig = sessionSelectors?.getCustomerConfig(
          store.getState()
        );
        return customerConfig;
      },
      store.subscribe
    ],
    goals: [() => useGoalsStore.getState().goals, useGoalsStore.subscribe]
  },
  validate: (values, { customerConfig, goals }) => {
    let errors: ValidationErrors = {};

    if (!customerConfig) return errors;

    const {
      expectationOfRisk,
      riskStrategy,
      clientInformation,
      companyFinancialSituation,
      personFinancialSituation
    } = values;

    const expectationOfRiskValidationError =
      getExpectationOfRiskValidationError(expectationOfRisk);
    if (!R.isNil(expectationOfRiskValidationError)) {
      errors.expectationOfRisk = expectationOfRiskValidationError;
    }

    if (!customerConfig?.roboAdviceForm?.riskQuestions?.isRisk2Hidden) {
      const riskStrategyValidationError =
        getRiskStrategyValidationError(riskStrategy);
      if (!R.isNil(riskStrategyValidationError)) {
        errors.riskStrategy = riskStrategyValidationError;
      }
    }

    // ADVICE INFORMATION ERRORS
    errors = getCustomFieldsErrors({
      fields: customerConfig.roboAdviceForm.adviceInformation?.fields,
      values,
      errors
    });

    // ORDER INFORMATION ERRORS
    errors = getCustomFieldsErrors({
      fields: customerConfig.roboOrderFlow?.orderInformationFields,
      values,
      errors
    });

    // ORDER INFORMATION PAGE 2 ERRORS
    errors = getOrderInformationPage2Errors({
      sections:
        customerConfig.roboOrderFlow?.orderInformationPage2?.[
          clientInformation?.clientType
        ]?.sections,
      values,
      errors
    });

    // FINANCIAL SITUATION ERRORS
    errors = getFinancialSituationValidationErrors({
      clientInformation,
      errors,
      financialSituation:
        clientInformation?.clientType === ClientTypes.company
          ? companyFinancialSituation
          : personFinancialSituation,
      financialSituationConfig:
        customerConfig?.roboAdviceForm?.financialSituation
    });

    // KNOWLEDGE AND EXPERIENCE QUESTIONS ERRORS
    errors = getKnowledgeAndExperienceQuestionsValidationErrors({
      values,
      errors,
      knowledgeAndExperienceQuestions:
        customerConfig?.roboAdviceForm?.knowledgeAndExperienceQuestions
          ?.questions
    });

    const isAssetClassAllocationAdvisorNotesEnabled =
      customerConfig?.advisoryComponents?.customPortfolio?.assetClassAllocation
        ?.advisorNotes?.enabled;
    const isAssetClassAllocationAdvisorNotesRequired =
      customerConfig?.advisoryComponents?.customPortfolio?.assetClassAllocation
        ?.advisorNotes?.required;

    const isFundAllocationAdvisorNotesEnabled =
      customerConfig?.advisoryComponents?.customPortfolio?.fundAllocation
        ?.advisorNotes?.enabled;
    const isFundAllocationAdvisorNotesRequired =
      customerConfig?.advisoryComponents?.customPortfolio?.fundAllocation
        ?.advisorNotes?.required;

    errors = getAdvisoryValidationErrors(
      values,
      errors,
      isAssetClassAllocationAdvisorNotesEnabled,
      isAssetClassAllocationAdvisorNotesRequired,
      isFundAllocationAdvisorNotesEnabled,
      isFundAllocationAdvisorNotesRequired,
      goals
    );

    errors = getSustainabilityErrors({ values, errors, customerConfig });

    return errors;
  },
  mutators: {
    ...arrayMutators,
    setFieldTouched
  }
});

export type UseAdviceSessionForm = UseFinalFormStore<AdviceSessionFormValues>;

export const useForm = createUseFinalFormAdapter({
  form
});
