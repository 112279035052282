import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCurrentDate } from '../../../shared/utils';
import { createAdviceSession as createAdviceSessionApi } from '../../main/api';
import { clientForm } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { setLastAddedSessionHighlight } from '../services/lastAddedSessionHighlightStorage';
import {
  mapClientAdviceSessionToServer,
  mapServerAdviceSessionToClient
} from '../services/mapping';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useCreateAdviceSession() {
  const { clientId } = useParams<AdviceSessionParams>();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const currentUser: any = useSelector(sessionSelectors.getCurrentUser());
  const i18n = useI18n();

  const dispatch = useDispatch();

  const createAdviceSession = async payload => {
    const clientPageStoreState = useClientPageStore.getState();

    try {
      clientPageStoreState.setIsCreateAdviceSessionPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const response = await createAdviceSessionApi(
        accessToken,
        undefined,
        mapClientAdviceSessionToServer(currentUser, clientId, payload)
      );

      setLastAddedSessionHighlight(response.data.session_id);

      clientForm.mutators.push('adviceSessions', {
        ...mapServerAdviceSessionToClient(response.data),
        newTimestamp: getCurrentDate().getTime()
      });

      clientPageStoreState.setIsCreateAdviceSessionPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientPageStoreState.setIsCreateAdviceSessionPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.client.createAdviceSessionErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return createAdviceSession;
}
