import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import * as R from 'ramda';

import { useTheme } from 'modules/styles/components/styles.js';
import { useI18n } from 'modules/settings/components/settings.js';
import { formatNumber } from 'modules/shared/utils/number.js';
import { hexOpacity } from 'modules/shared/constants/colors';
import { TextFontSizes, FontFamilies } from 'modules/shared/constants/fonts';
import Legend from 'modules/shared/components/legend';

// column width = one year in milliseconds
// padding = 0.1 of column width (pointPadding default value 0.1)
const columnPadding = (365 * 24 * 3600 * 1000) / 10;

const CostChart = ({
  cultureCode,
  items,
  startYear = new Date().getUTCFullYear(),
  width,
  onLoad,
  showLegend = false,
  customColors = null
}) => {
  const chartComponent = React.useRef();
  const i18n = useI18n();
  const theme = useTheme();

  const colors = {
    costColor: customColors?.costColor || theme.chartPortfolioColors[1],
    valueAfterCostColor:
      customColors?.valueAfterCostColor || theme.chartPortfolioColors[0],
    labelsColor: customColors?.labelsColor || theme.secondaryColor
  };

  const series = React.useMemo(
    () => [
      {
        data: items.map((el, index) => [
          Date.UTC(startYear + index),
          el.valueBeforeCost - el.valueAfterCost
        ]),
        color: colors.costColor
      },
      {
        data: items.map((el, index) => [
          Date.UTC(startYear + index),
          el.valueAfterCost
        ]),
        color: colors.valueAfterCostColor
      }
    ],
    [colors.costColor, colors.valueAfterCostColor, items, startYear]
  );

  const chartOptions = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      animation: false,
      events: {
        load: onLoad
      }
    },
    title: {
      text: null
    },
    xAxis: {
      labels: {
        format: '{value: %Y}',
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        y: 35
      },
      min: items.length > 2 ? Date.UTC(startYear) + columnPadding : undefined,
      max:
        items.length > 2
          ? Date.UTC(startYear + items.length - 1) - columnPadding
          : undefined,
      lineWidth: 0,
      tickWidth: 0,
      type: 'datetime'
    },
    yAxis: {
      gridLineColor: `${colors.labelsColor}${hexOpacity[50]}`,
      labels: {
        formatter: ({ value }) => formatNumber(cultureCode, value, 0, 0),
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        x: -32
      },
      min: 0,
      tickAmount: 6,
      title: {
        enabled: false
      }
    },
    tooltip: {
      headerFormat: '',
      formatter: function formatTooltip() {
        const { point, series } = this;
        const index = series.data.indexOf(point);
        const item = items[index];

        return (
          `<div>` +
          `${i18n('costChart.tooltip.expectedValueBeforeCost')}: ${formatNumber(
            cultureCode,
            item.valueBeforeCost
          )} <br/>` +
          `${i18n('costChart.tooltip.expectedValueAfterCost')} ${formatNumber(
            cultureCode,
            item.valueAfterCost
          )} <br/>` +
          `${i18n('costChart.tooltip.deposits')}: ${formatNumber(
            cultureCode,
            item.deposits
          )}  <br/>` +
          '</div>'
        );
      }
    },
    plotOptions: {
      column: {
        animation: false,
        borderWidth: 0,
        groupPadding: 0,
        stacking: 'normal',
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series
  };

  return (
    <>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{
          style: {
            height: 271,
            width: R.isNil(width) ? 'auto' : `${width}px`
          }
        }}
      />

      {showLegend && (
        <Legend
          items={[
            {
              title: i18n('costChart.default.totalValue'),
              style: { backgroundColor: colors.valueAfterCostColor }
            },
            {
              title: i18n('costChart.default.totalCost'),
              style: { backgroundColor: colors.costColor }
            }
          ]}
        />
      )}
    </>
  );
};

export default CostChart;
