import React, { useEffect } from 'react';

import { useForm as useRoboAdviceForm } from '../../../form/services/form';
import { useIsSessionReadOnly } from '../../../main/services/selectors';
import Widget from '../../../shared/components/widget';
import { FieldSections, getFinancialSituationFields } from '../../constants';
import { RenderFields } from '../shared.js';
import { useInitCompanyAccountingFigures } from './useInitCompanyAccountingFigures';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { useCheckIsOrgNumberValid } from 'features/roboAdvice/client/kyc/components/eninElements/useCheckIsOrgNumberValid';
import { SupportedENINCountryCodes } from 'features/roboAdvice/shared/constants';
import ENINDate from 'features/shared/components/eninDate';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  refreshIcon: {
    marginLeft: Spacing.spacing02,
    cursor: 'pointer',

    '& path': {
      fill: theme.accentColor
    }
  }
}));

type Props = {
  hasError: boolean;
  errors: ErrorMessage[];
};

const CompanyAccountingFigures = ({ hasError, errors }: Props) => {
  const initCompanyAccountingFigures = useInitCompanyAccountingFigures();
  const i18n = useI18n();
  const classes = useStyles();
  const isSessionReadOnly = useIsSessionReadOnly();
  const checkIsOrgNumberValid = useCheckIsOrgNumberValid();
  const {
    roboAdviceForm: {
      financialSituation: {
        accountingFigures,
        isEninAccountingFiguresEnabled = false
      }
    }
  } = useCustomerConfig();
  const {
    values: { clientInformation, companyFinancialSituation }
  } = useRoboAdviceForm();
  const currentCountryCode = clientInformation?.country?.toLowerCase() || '';
  const eninUpdateDate = companyFinancialSituation?.eninUpdateDate;

  const fields = getFinancialSituationFields(
    accountingFigures,
    FieldSections.companyAccountingFigures,
    ClientTypes.company
  );

  const isClientCountrySupported = (
    SupportedENINCountryCodes as readonly string[]
  ).includes(currentCountryCode);
  const isEninEnabled =
    isClientCountrySupported && isEninAccountingFiguresEnabled;

  useEffect(() => {
    if (isEninEnabled) {
      checkIsOrgNumberValid() && initCompanyAccountingFigures(false);
    }
  }, []);

  return (
    <div className={classes.root}>
      {errors.map(error => (
        <InputErrorMessage message={error.alertMessage} />
      ))}
      <Widget
        header={i18n('roboAdvice.financialSituation.accountingFigures')}
        headerButton={
          isEninEnabled ? (
            <RefreshIcon
              className={classes.refreshIcon}
              onClick={() =>
                checkIsOrgNumberValid() && initCompanyAccountingFigures(true)
              }
            />
          ) : undefined
        }
        doShowEmptyFooter={!isEninEnabled || !eninUpdateDate}
        elementId={'company-accounting-figures'}
        hasError={hasError}
      >
        <div>
          <RenderFields
            fields={fields}
            adjustElement={(field, element) =>
              React.cloneElement(element, {
                disabled: isSessionReadOnly
              })
            }
            allowNegative
          />
          {isEninEnabled && eninUpdateDate && (
            <ENINDate date={eninUpdateDate} />
          )}
        </div>
      </Widget>
    </div>
  );
};

export default CompanyAccountingFigures;
