import * as R from 'ramda';
import * as React from 'react';
import { Form } from 'react-final-form';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import {
  RoboAdviceClientsListPages,
  RoboAdviceClientPages,
  RoboAdviceAdviceSessionPages,
  NewClientId
} from '../../../shared/constants';
import AdviceSessionsList from '../../adviceSessionsList/components/index';
import ClientInformation from '../../clientInformation/components/index';
import KYC from '../../kyc/components';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { clientForm, useClientForm } from '../services/clientForm';
import { useCleanupPage } from './useCleanupPage';
import { useInitPage } from './useInitPage';
import { useIsClientTabDisabled } from './useIsClientTabDisabled';
import { ClientParams } from 'features/roboAdvice/client/shared/types';
import Breadcrumbs from 'features/shared/components/breadcrumbs';
import Link from 'features/shared/components/link/index.js';
import { BigTabs, BigTab } from 'features/shared/components/tabs/big';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useQuery } from 'features/sharedModules/reactRouter/components/useQuery';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

// Temporary solution until types added to BigTab
const AnyBigTab = BigTab as any;

const useStyles = createUseStyles({
  wrapper: {
    width: '85%'
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  backToSessionLink: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  tabs: {
    marginBottom: Spacing.spacing02
  }
});

const Client = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const { clientId, pageId, clientType } = useParams<ClientParams>();
  const clientPageStore = useClientPageStore();
  const { isAdviceSessionsTabDisabled, isKYCTabDisabled } =
    useIsClientTabDisabled();
  const { values } = useClientForm();
  const initPage = useInitPage();
  const cleanupPage = useCleanupPage();
  const {
    roboAdvice: {
      kyc: {
        company: { isKYCEnabled: companySetting },
        person: { isKYCEnabled: personSetting }
      }
    }
  } = useCustomerConfig();

  React.useEffect(() => {
    initPage();

    return () => {
      cleanupPage();
    };
  }, []);

  const isKYCEnabled =
    values.clientInformation?.clientType === ClientTypes.company
      ? companySetting
      : personSetting;

  const clientName =
    clientId === NewClientId
      ? i18n('roboAdvice.breadcrumbs.addClient')
      : clientPageStore.clientName;
  const breadcrumbs = R.isNil(clientName)
    ? []
    : [
        {
          title: i18n('roboAdvice.breadcrumbs.advisorSolution'),
          to: routeTemplates.roboAdviceClientsList.build(
            RoboAdviceClientsListPages.allClients
          )
        },
        {
          title: i18n('roboAdvice.breadcrumbs.clients'),
          to: routeTemplates.roboAdviceClientsList.build(
            RoboAdviceClientsListPages.myClients,
            {
              clientType: query.clientType,
              clientId: query.clientId,
              adviceSessionId: query.adviceSessionId
            }
          )
        },
        {
          title: clientName
        }
      ];

  return (
    <div data-testid={'client-page'} className={classes.wrapper}>
      <div className={classes.header}>
        <Breadcrumbs items={breadcrumbs} />
        {!R.isNil(query.clientType) &&
          !R.isNil(query.clientId) &&
          !R.isNil(query.adviceSessionId) && (
            <Link
              className={classes.backToSessionLink}
              to={routeTemplates.roboAdviceAdviceSession.build(
                query.clientType,
                query.clientId,
                query.adviceSessionId,
                RoboAdviceAdviceSessionPages.adviceInformation
              )}
            >
              {i18n('roboAdvice.shared.backToTheSession')}
            </Link>
          )}
      </div>
      <BigTabs
        rootClassName={classes.tabs}
        value={pageId}
        onChange={(event, newPageId) => {
          history.push(
            routeTemplates.roboAdviceClient.build(
              clientType,
              clientId,
              newPageId,
              query
            )
          );
        }}
      >
        <AnyBigTab
          value={RoboAdviceClientPages.clientInformation}
          label={i18n('roboAdvice.client.clientInformation')}
        />
        {isKYCEnabled && (
          <AnyBigTab
            value={RoboAdviceClientPages.kyc}
            label={i18n('roboAdvice.client.kyc')}
            disabled={isKYCTabDisabled}
          />
        )}
        <AnyBigTab
          value={RoboAdviceClientPages.adviceSessions}
          label={i18n('roboAdvice.client.adviceSessions')}
          disabled={isAdviceSessionsTabDisabled}
        />
      </BigTabs>
      <Form form={clientForm} onSubmit={() => {}}>
        {() => (
          <>
            {pageId === RoboAdviceClientPages.clientInformation && (
              <ClientInformation />
            )}

            {pageId === RoboAdviceClientPages.kyc &&
              isKYCEnabled &&
              !isKYCTabDisabled && <KYC />}

            {((pageId === RoboAdviceClientPages.adviceSessions &&
              isAdviceSessionsTabDisabled) ||
              (pageId === RoboAdviceClientPages.kyc &&
                (isKYCTabDisabled || !isKYCEnabled))) && (
              <Redirect
                to={routeTemplates.roboAdviceClient.build(
                  clientType,
                  clientId,
                  RoboAdviceClientPages.clientInformation,
                  query
                )}
              />
            )}

            {pageId === RoboAdviceClientPages.adviceSessions &&
              !isAdviceSessionsTabDisabled && <AdviceSessionsList />}
          </>
        )}
      </Form>
    </div>
  );
};

export default Client;
