import classNames from 'classnames';
import * as R from 'ramda';
import React, { useReducer, useRef, useEffect } from 'react';
import { Manager, Reference, Popper, PopperProps } from 'react-popper';

import Input, { InputProps } from './input';
import Menu, { MenuProps } from './menu';
import useOnClickOutside from 'features/shared/hooks/useClickOutside';
import { useLazyEffect } from 'features/shared/hooks/useLazyEffect.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const actionTypes = {
  TOGGLE_DROPDOWN_MENU: 'TOGGLE_DROPDOWN_MENU'
};

const initialState = {
  isDropdownMenuOpen: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DROPDOWN_MENU: {
      return R.assoc('isDropdownMenuOpen', action.payload, state);
    }
    default:
      return state;
  }
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',

    '&$open': {
      zIndex: 2
    }
  },
  open: {}
});

type Props = {
  options: { key: string; title: React.ReactNode }[];
  onChange: (
    key: string,
    option: { key: string; title: React.ReactNode }
  ) => void;
  inputRef?: React.RefObject<HTMLDivElement>;
  value: string | null | undefined;
  width?: string | number;
  input?: () => React.ReactNode;
  menu?: () => React.ReactNode;
  isDropdownMenuOpen?: boolean;
  inputProps?: Partial<InputProps>;
  menuProps?: Partial<MenuProps>;
  popperProps?: PopperProps;
  noDataTitle?: string;
  className?: string;
  doPreventInputFocusLoss?: boolean;
  disabled?: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
};

const Dropdown = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      inputRef,
      value,
      width = '230px',
      options,
      onChange,
      input,
      menu,
      isDropdownMenuOpen,
      inputProps = {} as InputProps,
      menuProps = {} as MenuProps,
      popperProps = {} as PopperProps,
      noDataTitle,
      className,
      doPreventInputFocusLoss = true,
      disabled,
      onOpened,
      onClosed,
      onMouseEnter,
      onMouseLeave,
      ...restProps
    },
    ref
  ) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);

    const mergedPopperProps = R.over(
      R.lensPath(['modifiers', 'computeStyle']),
      m =>
        R.isNil(m)
          ? {
              y: 'left' // -1 horizontal offset bug fix. see popper-core getRoundedOffsets 'popper.left - 1'.
            }
          : m,
      popperProps
    );

    const menuRef = React.useRef(null);
    const inputWrapRef = React.useRef(null);

    useOnClickOutside([menuRef, inputWrapRef], () => {
      dispatch({
        type: actionTypes.TOGGLE_DROPDOWN_MENU,
        payload: false
      });
    });

    const popperRef = useRef<{ scheduleUpdate: () => void }>();

    useEffect(() => {
      popperRef.current && popperRef.current.scheduleUpdate();
    }, []);

    const selectedOption = R.find(option => option.key === value, options);

    const renderInput = input || ((props: InputProps) => <Input {...props} />);
    const renderMenu = menu || ((props: MenuProps) => <Menu {...props} />);

    const isDropdownMenuOpenValue = R.isNil(isDropdownMenuOpen)
      ? state.isDropdownMenuOpen
      : isDropdownMenuOpen;

    useLazyEffect(() => {
      if (isDropdownMenuOpenValue) {
        onOpened && onOpened();
      } else {
        onClosed && onClosed();
      }
    }, [isDropdownMenuOpenValue]);

    return (
      <div
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid={restProps['data-testid']}
        className={classNames(
          classes.container,
          {
            [classes.open]: isDropdownMenuOpenValue
          },
          className
        )}
        style={{ width: width }}
      >
        <Manager>
          <Reference>
            {({ ref }) => {
              return renderInput({
                ...inputProps,
                disabled,
                forwardedRef: ref as React.RefObject<HTMLDivElement>,
                internalForwardedRef: inputRef,
                inputWrapRef: inputWrapRef,
                onClick: () => {
                  if (R.isNil(isDropdownMenuOpen)) {
                    dispatch({
                      type: actionTypes.TOGGLE_DROPDOWN_MENU,
                      payload: !state.isDropdownMenuOpen
                    });
                  }
                },
                selectedOption,
                isDropdownMenuOpen: isDropdownMenuOpenValue,
                onBlur: e => {
                  dispatch({
                    type: actionTypes.TOGGLE_DROPDOWN_MENU,
                    payload: false
                  });

                  inputProps.onBlur && inputProps.onBlur(e);
                }
              });
            }}
          </Reference>
          {isDropdownMenuOpenValue && (
            <Popper placement="bottom" {...mergedPopperProps}>
              {({ ref, style, placement, scheduleUpdate }) => {
                popperRef.current = { scheduleUpdate };

                return renderMenu({
                  ...menuProps,
                  forwardedRef: ref as React.RefObject<HTMLDivElement>,
                  menuRef: menuRef,
                  width: width,
                  style,
                  placement,
                  options,
                  noDataTitle,
                  onSelect: option => {
                    onChange && onChange(option.key, option);

                    if (R.isNil(isDropdownMenuOpen)) {
                      dispatch({
                        type: actionTypes.TOGGLE_DROPDOWN_MENU,
                        payload: false
                      });
                    }
                  },
                  // TODO: Fast and easy solution for input focus loss preventing. If will not work change to react-day-picker solution (blur and timeout).
                  onMouseDown: doPreventInputFocusLoss
                    ? e => {
                        e.preventDefault();
                      }
                    : undefined
                });
              }}
            </Popper>
          )}
        </Manager>
      </div>
    );
  }
);

export default Dropdown;
