import { ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import { isEmpty, isNil, lensPath, path, set, trim } from 'ramda';

import { SessionStatus } from '../../../shared/types';
import { getOrgNumberErrors } from '../../shared/utils';
import { KYC, RoboAdvice } from 'defaults/defaultRoboAdvice';
import store from 'features/main/app/store.js';
import { ChannelName } from 'features/roboAdvice/client/adviceSessionsList/services/mapping';
import { InvestorTypes } from 'features/roboAdvice/client/kyc/components/automaticClientClassification/constants';
import {
  getDefaultErrorMessage,
  getFieldsWithTextFields
} from 'features/roboAdvice/shared/utils';
import { ClientTypes, ClientType } from 'features/shared/constants/session';
import sessionSelectors from 'features/shared/services/session/selectors';
import { CustomerConfigType } from 'features/shared/services/session/types';
import { getEmptyValidationRuleConfig } from 'features/sharedModules/declarativeForm/utils.js';
import {
  createForm,
  FormApi
} from 'features/sharedModules/finalForm/services/finalForm';
import {
  createUseFinalFormAdapter,
  FormState,
  UseFinalFormStore
} from 'features/sharedModules/zustand/components/finalFormAdapter';

export type Contact = {
  name: string;
  email: string;
  phone: string;
  role: string;
  ubo?: boolean;
  signRight?: boolean;
};

export type ClientInformation = {
  additionalData?: Record<string, any> | null;
  address?: string | null;
  city?: string | null;
  clientType?: ClientType | null;
  companyName?: string | null;
  contacts?: Contact[] | null;
  country?: string | null;
  email?: string | null;
  name?: string | null;
  orgNumber?: string | null;
  phoneNumber?: string | null;
  ssn?: string | null;
  zipCode?: string | null;
};

export type AdviceSession = {
  advisor: {
    advisorId: string;
    email: string;
    name: string;
  };
  id: string;
  newTimestamp?: number;
  name?: string;
  created?: string;
  lastActivityDate?: string;
  channel?: ChannelName;
  status?: SessionStatus;
  adviceType?: string | null;
};

export type CompanyFlag = {
  flagDate: string;
  flagLabel: string;
  icon: string;
};

export type UBOListItem = {
  ownership: number;
  identity: string;
  name: string;
  country: string;
};

export type signatureRightData = {
  legalRights: {
    text: string;
    fromDate: string;
    toDate: string;
  }[];
  currentRoles: {
    fromDate: string;
    toDate: string;
    personFullName: string;
    roleTranslationsKey: string;
  }[];
};

export type ClientFormValues = {
  clientInformation?: ClientInformation;
  adviceSessions?: AdviceSession[];
  kyc?: {
    automaticClientClassification?: {
      clientClassificationId?: string | null;
      answer?: string | string[];
      investorType?: typeof InvestorTypes[keyof typeof InvestorTypes];
      isDecisionOverwritten?: boolean;
      reclassificationRequestDocumentation?: string;
    };
    enin?: {
      companyFlags?: {
        eninUpdateDate?: string;
        flagList?: CompanyFlag[];
      };
      ubo?: {
        eninUpdateDate?: string;
        uboList?: UBOListItem[];
      };
      signatureRight?: {
        eninUpdateDate?: string;
        signatureRightData?: signatureRightData;
      };
    };
    [x: string]: any;
  } | null;
};

export type ClientFormState = FormState<ClientFormValues>;

export type ClientForm = FormApi<ClientFormValues>;

const getFieldsErrors = ({ fields, values, errors }) => {
  return fields
    ? fields
        .filter(
          ({ required, name }) =>
            required || name === 'clientInformation.country'
        )
        .filter(f => {
          const fieldValue: string | undefined = path(
            f.name.split('.'),
            values
          );

          return !isNil(fieldValue) && !isEmpty(fieldValue)
            ? fieldValue.toString().trim().length === 0
            : true;
        })
        .reduce((acc, curr) => {
          return set(
            lensPath(curr.name.split('.')),
            getDefaultErrorMessage(
              curr.componentType,
              curr.additionalTextField
            ),
            acc
          );
        }, errors)
    : errors;
};

const getContactsError = ({ values, errors, required }) => {
  const contacts = values.clientInformation?.contacts;

  return !required ||
    (!!contacts &&
      contacts.every(
        ({ name, email, phone, role }) => !!(name && email && phone && role)
      ))
    ? errors
    : set(
        lensPath(['clientInformation', 'contactList']),
        'roboAdvice.clientInformation.contacts.errorMessage',
        errors
      );
};

const getSignatureRightError = ({ values, errors }) => {
  const signatureRight = values.kyc?.signatureRight || [];
  const contactsWithSignatureRight = (
    values.clientInformation?.contacts || []
  ).filter(({ signRight }) => !!signRight);

  const isSignatureRightValid = signatureRight.every(
    ({ name, identity, country, email }) =>
      !!(name && identity && country && email)
  );
  const isContactsWithSignatureRightValid = contactsWithSignatureRight.every(
    ({ identity, country }) => !!(identity && country)
  );

  if (contactsWithSignatureRight.length === 0) {
    return isSignatureRightValid
      ? errors
      : set(
          lensPath(['kyc', 'signatureRightTable']),
          'roboAdvice.kyc.signatureRight.errorMessage',
          errors
        );
  }

  return isContactsWithSignatureRightValid &&
    (isSignatureRightValid || isEmpty(values.kyc?.signatureRight[0]))
    ? errors
    : set(
        lensPath(['kyc', 'signatureRightTable']),
        'roboAdvice.kyc.signatureRight.errorMessage',
        errors
      );
};

const getUboError = ({ values, errors }) => {
  const ubo = values.kyc?.ubo || [];
  const contactsWithUbo = (values.clientInformation?.contacts || []).filter(
    ({ ubo }) => !!ubo
  );

  const ownershipSum = ubo
    .concat(contactsWithUbo)
    .reduce((acc, curr) => acc + (curr.ownership || 0), 0);

  if (ownershipSum > 100) {
    return set(
      lensPath(['kyc', 'uboTable']),
      'roboAdvice.kyc.ubo.ownershipErrorMessage',
      errors
    );
  }

  const isUboValid = ubo.every(
    ({ name, identity, country, ownership }) =>
      !!(name && identity && country && ownership)
  );
  const isContactsWithUboValid = contactsWithUbo.every(
    ({ identity, country, ownership }) => !!(identity && country && ownership)
  );

  if (contactsWithUbo.length === 0) {
    return isUboValid
      ? errors
      : set(
          lensPath(['kyc', 'uboTable']),
          'roboAdvice.kyc.ubo.errorMessage',
          errors
        );
  }

  return isContactsWithUboValid && (isUboValid || isEmpty(values.kyc?.ubo[0]))
    ? errors
    : set(
        lensPath(['kyc', 'uboTable']),
        'roboAdvice.kyc.ubo.errorMessage',
        errors
      );
};

const getAutomaticClientClassificationErrors = ({
  defaultFields,
  automaticClientClassification,
  values,
  errors
}: {
  defaultFields: KYC['defaultFields'];
  automaticClientClassification: RoboAdvice['clientInformation']['automaticClientClassification'];
  values: ClientFormValues;
  errors: ValidationErrors;
}) => {
  if (defaultFields?.automaticClientClassification?.isEnabled) {
    const {
      investorType,
      isDecisionOverwritten,
      reclassificationRequestDocumentation
    } = values?.kyc?.automaticClientClassification || {};

    if (
      automaticClientClassification?.checklistModal?.isCommentEnabled &&
      automaticClientClassification?.checklistModal?.isCommentRequired &&
      isDecisionOverwritten &&
      (isNil(reclassificationRequestDocumentation) ||
        reclassificationRequestDocumentation.trim().length === 0)
    ) {
      errors = set(
        lensPath([
          'kyc',
          'automaticClientClassification',
          'reclassificationRequestDocumentation'
        ]),
        'roboAdvice.kyc.automaticClientClassification.reclassificationRequestDocumentation.error',
        errors
      );
    }

    if (isNil(investorType) || isEmpty(investorType)) {
      errors = set(
        lensPath(['kyc', 'automaticClientClassification', 'investorType']),
        'roboAdvice.kyc.automaticClientClassification.investorTypeError',
        errors
      );
    }
  }

  return errors;
};

const getClientNameErrors = ({ fields, values, errors }) => {
  return fields
    ? fields
        .filter(
          ({ name }) =>
            name === 'clientInformation.name' ||
            name === 'clientInformation.companyName'
        )
        .filter(f => {
          const fieldValue: string | undefined = path(
            f.name.split('.'),
            values
          );
          return !isNil(fieldValue) && fieldValue?.length > 100;
        })
        .reduce((acc, curr) => {
          return set(
            lensPath(curr.name.split('.')),
            'shared.error.toLongName',
            acc
          );
        }, errors)
    : errors;
};

export const clientForm = createForm<ClientFormValues>({
  onSubmit: () => {},
  validationDependencies: {
    customerConfig: [
      () => {
        const customerConfig: CustomerConfigType =
          sessionSelectors.getCustomerConfig(store.getState());
        return customerConfig;
      },
      store.subscribe
    ]
  },
  mutators: {
    ...arrayMutators,
    setFieldTouched
  },
  validate: (values, { customerConfig }) => {
    const {
      roboAdvice: {
        clientInformation: {
          person,
          company,
          contacts,
          automaticClientClassification
        },
        kyc: {
          company: {
            fields: companyKYCFields,
            defaultFields: companyDefaultFields,
            isKYCEnabled: isCompanyKYCEnabled
          },
          person: {
            fields: personKYCFields,
            defaultFields: personDefaultFields,
            isKYCEnabled: isPersonKYCEnabled
          }
        }
      } = {
        clientInformation: {
          person: [],
          company: [],
          contacts: null
        },
        kyc: {
          company: { isKYCEnabled: false, fields: [], defaultFields: {} },
          person: { isKYCEnabled: false, fields: [], defaultFields: {} }
        }
      }
    } = customerConfig as CustomerConfigType;

    let errors: ValidationErrors = {};
    const isKYCEnabled =
      values.clientInformation?.clientType === ClientTypes.company
        ? isCompanyKYCEnabled
        : isPersonKYCEnabled;

    errors = (values.adviceSessions ?? []).reduce((acc, s, index) => {
      if (isNil(s.name) || isEmpty(trim(s.name))) {
        return set(
          lensPath(['adviceSessions', index, 'name']),
          getEmptyValidationRuleConfig(
            'roboAdvice.client.adviceSessionsTable.name',
            'shared.emptyInputErrorMessage'
          ).errorMessageConfig,
          acc
        );
      }

      return acc;
    }, errors);

    if (values.clientInformation?.clientType === ClientTypes.person) {
      errors = getFieldsErrors({
        fields: [...person, ...getFieldsWithTextFields(person, values)],
        values,
        errors
      });

      errors = getClientNameErrors({ fields: person, values, errors });

      errors = getAutomaticClientClassificationErrors({
        defaultFields: personDefaultFields,
        automaticClientClassification,
        values,
        errors
      });
    }

    if (values.clientInformation?.clientType === ClientTypes.company) {
      errors = getOrgNumberErrors({
        values,
        errors
      });

      errors = getFieldsErrors({
        fields: [...company, ...getFieldsWithTextFields(company, values)],
        values,
        errors
      });

      errors = getClientNameErrors({ fields: company, values, errors });

      if (contacts?.isContactListEnabled) {
        errors = getContactsError({
          values,
          errors,
          required: contacts.required
        });
      }

      errors = getAutomaticClientClassificationErrors({
        defaultFields: companyDefaultFields,
        automaticClientClassification,
        values,
        errors
      });
    }

    if (!isEmpty(values) && isKYCEnabled) {
      const fields =
        values.clientInformation?.clientType === ClientTypes.company
          ? companyKYCFields
          : personKYCFields;
      const fieldsWithTextFields = getFieldsWithTextFields(fields, values);

      errors = getFieldsErrors({
        fields: [...fields, ...fieldsWithTextFields],
        values,
        errors
      });

      if (values.clientInformation?.clientType === ClientTypes.company) {
        const signatureRightField = fields.find(
          f => f.name === 'kyc.signatureRight'
        );
        const uboField = fields.find(f => f.name === 'kyc.ubo');

        if (
          signatureRightField &&
          signatureRightField.isEnabled &&
          signatureRightField.required
        ) {
          errors = getSignatureRightError({ values, errors });
        }
        if (uboField && uboField.isEnabled && uboField.required) {
          errors = getUboError({ values, errors });
        }
      }
    }

    return errors;
  }
});

export type UseClientForm = UseFinalFormStore<ClientFormValues>;

export const useClientForm = createUseFinalFormAdapter({
  form: clientForm,
  formSubscription: {
    values: true,
    errors: true,
    touched: true,
    pristine: true
  }
});
