import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useReadDataListener } from '../../../shared/components/useReadDataListener';
import { useGoalsStore } from '../../../shared/services/goalsStore';
import { useTransactionListStore } from '../../../transactionListStore';
import { usePageStore } from '../../services/pageStore';
import useReadTransactionList from './useReadTransactionList';
import { ReactComponent as BuyIcon } from 'assets/buy.svg';
import { ReactComponent as KeepIcon } from 'assets/keep.svg';
import { ReactComponent as SellIcon } from 'assets/sell.svg';
import SimpleTable from 'features/shared/components/table/simpleTable';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  header: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: '23px',
    textAlign: 'left',
    marginBottom: 32,

    '&:not(:first-child)': {
      margin: '96px 0 32px'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 64
  },
  subHeader: {
    textAlign: 'left',
    fontWeight: FontWeights.normal,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    marginBottom: 16
  },
  sumTable: {
    width: '30%',
    [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
      width: '50%'
    }
  },
  numberValueColumn: {
    textAlign: 'right'
  },
  actionColumn: {
    '&>span': {
      verticalAlign: 'middle'
    }
  },
  keepIcon: {
    '&>svg': {
      margin: `2px 4px 0 -3px`
    }
  },
  sellIcon: {
    '&>svg': {
      margin: `0 ${Spacing.spacing00}px 3px 0`
    }
  },
  buyIcon: {
    '&>svg': {
      margin: `0 5px 3px -3px`
    }
  },
  redValue: {
    color: theme.errorNegativeColor
  },
  greenValue: {
    color: theme.successColor
  }
}));
const TransactionList = ({ isSelected }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { goals } = useGoalsStore();
  const { pageStatuses } = usePageStore();
  const transactionListStore = useTransactionListStore();

  const {
    analyticsComponents: { transactionListColumns }
  } = useCustomerConfig();

  useReadDataListener(
    pageStatuses.readTransactionList,
    isSelected,
    useReadTransactionList()
  );

  const firstDepositSum = useMemo(
    () => goals.reduce((acc, goal) => acc + (goal.data.firstDeposit || 0), 0),
    [goals]
  );

  const totalValues = useMemo(() => {
    return transactionListStore.transactions?.reduce(
      (acc, transaction) => {
        acc.existingPortfolio += transaction.existingPortfolioValue || 0;
        acc.toAdvisory += transaction.toAdvisory || 0;
        acc.targetValue += transaction.aggregatedTargetValue || 0;
        return acc;
      },
      {
        existingPortfolio: 0,
        toAdvisory: 0,
        targetValue: 0
      }
    );
  }, [transactionListStore.transactions]);

  return (
    <>
      <div className={classes.header}>
        {i18n('roboAdvice.proposal.transactionList.externalHoldings')}
      </div>
      <SimpleTable
        className={classes.sumTable}
        headers={() => <></>}
        body={({ rowClassName, cellClassName }) => (
          <>
            <tr className={rowClassName}>
              <td className={cellClassName}>{i18n('shared.sum')}</td>
              <td
                className={classNames(cellClassName, classes.numberValueColumn)}
              >
                {formatNumber(cultureCode, firstDepositSum, 0, 0)}
              </td>
            </tr>
          </>
        )}
      />

      <div className={classes.header}>
        {i18n('roboAdvice.proposal.transactionList.internalHoldings')}
      </div>

      <SimpleTable
        headers={({ headerCellClassName }) => (
          <>
            <th className={headerCellClassName}>
              {i18n('roboAdvice.proposal.orderSummary.fund')}
            </th>
            {transactionListColumns?.isin.enabled && (
              <th className={classNames(headerCellClassName)}>
                {i18n('shared.isin')}
              </th>
            )}
            {transactionListColumns?.existingPortfolio.enabled && (
              <th
                className={classNames(
                  headerCellClassName,
                  classes.numberValueColumn
                )}
              >
                {i18n('roboAdvice.advisory.portfolio.existingPortfolio')}
              </th>
            )}
            {transactionListColumns?.toAdvisory.enabled && (
              <th
                className={classNames(
                  headerCellClassName,
                  classes.numberValueColumn
                )}
              >
                {i18n(
                  'roboAdvice.financialSituation.amountForAdvice.toAdvisory'
                )}
              </th>
            )}
            {transactionListColumns?.targetValue.enabled && (
              <th
                className={classNames(
                  headerCellClassName,
                  classes.numberValueColumn
                )}
              >
                {i18n('roboAdvice.proposal.transactionList.table.targetValue')}
              </th>
            )}
            {transactionListColumns?.transactionValue.enabled && (
              <th
                className={classNames(
                  headerCellClassName,
                  classes.numberValueColumn
                )}
              >
                {i18n(
                  'roboAdvice.proposal.transactionList.table.transactionValue'
                )}
              </th>
            )}
            {transactionListColumns?.action.enabled && (
              <th className={classNames(headerCellClassName)}>
                {i18n('roboAdvice.proposal.transactionList.table.action')}
              </th>
            )}
          </>
        )}
        body={({ rowClassName, cellClassName }) => (
          <>
            {transactionListStore.transactions?.map(transaction => (
              <tr className={rowClassName}>
                <td className={cellClassName}>{transaction.name}</td>
                {transactionListColumns?.isin.enabled && (
                  <td className={cellClassName}>{transaction.isin}</td>
                )}
                {transactionListColumns?.existingPortfolio.enabled && (
                  <td
                    className={classNames(
                      cellClassName,
                      classes.numberValueColumn
                    )}
                  >
                    {formatNumber(
                      cultureCode,
                      transaction.existingPortfolioValue,
                      0,
                      0
                    )}
                  </td>
                )}
                {transactionListColumns?.toAdvisory.enabled && (
                  <td
                    className={classNames(
                      cellClassName,
                      classes.numberValueColumn
                    )}
                  >
                    {formatNumber(cultureCode, transaction.toAdvisory, 0, 0)}
                  </td>
                )}
                {transactionListColumns?.targetValue.enabled && (
                  <td
                    className={classNames(
                      cellClassName,
                      classes.numberValueColumn
                    )}
                  >
                    {formatNumber(
                      cultureCode,
                      transaction.aggregatedTargetValue,
                      0,
                      0
                    )}
                  </td>
                )}
                {transactionListColumns?.transactionValue.enabled && (
                  <td
                    className={classNames(
                      cellClassName,
                      classes.numberValueColumn,
                      {
                        [classes.redValue]: transaction.transactionValue < 0,
                        [classes.greenValue]: transaction.transactionValue > 0
                      }
                    )}
                  >
                    {formatNumber(
                      cultureCode,
                      transaction.transactionValue,
                      0,
                      0
                    )}
                  </td>
                )}
                {transactionListColumns?.action.enabled && (
                  <td
                    className={classNames(cellClassName, classes.actionColumn)}
                  >
                    {transaction.action === 'buy' && (
                      <>
                        <span className={classes.buyIcon}>
                          <BuyIcon />
                        </span>
                        {i18n('roboAdvice.proposal.orderSummary.buy')}
                      </>
                    )}
                    {transaction.action === 'sell' && (
                      <>
                        <span className={classes.sellIcon}>
                          <SellIcon />
                        </span>
                        {i18n('roboAdvice.proposal.orderSummary.sell')}
                      </>
                    )}
                    {transaction.action === 'keep' && (
                      <>
                        <span className={classes.keepIcon}>
                          <KeepIcon />
                        </span>
                        {i18n('roboAdvice.proposal.transactionList.table.keep')}
                      </>
                    )}
                  </td>
                )}
              </tr>
            ))}
            <tr className={rowClassName}>
              <td className={cellClassName}>{i18n('shared.total')}</td>
              {transactionListColumns?.isin.enabled && (
                <td className={cellClassName}></td>
              )}
              {transactionListColumns?.existingPortfolio.enabled && (
                <td
                  className={classNames(
                    cellClassName,
                    classes.numberValueColumn
                  )}
                >
                  {formatNumber(
                    cultureCode,
                    totalValues.existingPortfolio,
                    0,
                    0
                  )}
                </td>
              )}
              {transactionListColumns?.toAdvisory.enabled && (
                <td
                  className={classNames(
                    cellClassName,
                    classes.numberValueColumn
                  )}
                >
                  {formatNumber(cultureCode, totalValues.toAdvisory, 0, 0)}
                </td>
              )}
              {transactionListColumns?.targetValue.enabled && (
                <td
                  className={classNames(
                    cellClassName,
                    classes.numberValueColumn
                  )}
                >
                  {formatNumber(cultureCode, totalValues.targetValue, 0, 0)}
                </td>
              )}
              {transactionListColumns?.transactionValue.enabled && (
                <td className={cellClassName}></td>
              )}
              {transactionListColumns?.action.enabled && (
                <td
                  className={classNames(
                    cellClassName,
                    classes.numberValueColumn
                  )}
                ></td>
              )}
            </tr>
          </>
        )}
      />
    </>
  );
};

export default TransactionList;
