import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { isNil } from 'ramda';

import { useI18n } from 'modules/settings/components/settings.js';
import { useStyles } from 'modules/expectedValueChart/default/components/expectedValueChart';
import { useTheme } from 'modules/styles/components/styles.js';
import { FontFamilies, TextFontSizes } from 'modules/shared/constants/fonts.js';
import { formatNumber } from 'modules/shared/utils/number.js';
import { hexOpacity } from 'modules/shared/constants/colors';
import Legend from 'modules/shared/components/legend';
import { useSetHighchartsLocaleMonths } from 'modules/shared/utils/months';

HighchartsMore(Highcharts);

const ExpectedPathChart = ({
  cultureCode,
  chartData,
  isInteractive,
  onLoad,
  width,
  customColors = null
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const theme = useTheme();
  const chartComponent = React.useRef();
  useSetHighchartsLocaleMonths();

  const expectedReturnChartData = chartData?.expectedReturn;
  const boundsChartData = chartData?.bounds;
  const colors = {
    expectedReturnColor:
      customColors?.expectedReturnColor || theme.chartPortfolioColors[0],
    boundsColor: customColors?.boundsColor || theme.chartPortfolioColors[0],
    labelsColor: customColors?.labelsColor || theme.secondaryColor
  };

  const series = React.useMemo(
    () => [
      {
        id: 'expectedReturn',
        type: 'line',
        zIndex: 1,
        color: colors.expectedReturnColor,
        custom: {
          type: i18n('expectedPathChart.default.expectedValue'),
          isMean: true
        },
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {
            lineWidthPlus: 0
          }
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
              radiusPlus: 0
            }
          }
        },
        data: expectedReturnChartData
      },
      {
        id: 'bounds',
        type: 'arearange',
        zIndex: 0,
        color: `${colors.boundsColor}${hexOpacity[60]}`,
        lineWidth: 0,
        data: boundsChartData,
        custom: {
          type: i18n('expectedPathChart.default.upperAndLowerBound'),
          isMean: false
        },
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {
            lineWidthPlus: 0
          }
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
              radiusPlus: 0
            }
          }
        }
      }
    ],
    [
      colors.expectedReturnColor,
      colors.boundsColor,
      i18n,
      expectedReturnChartData,
      boundsChartData
    ]
  );

  React.useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    window.addEventListener('resize', reflow);

    reflow();

    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  const chartOptions = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      marginTop: 20,
      marginRight: 0,
      animation: false,
      events: {
        load: onLoad
      }
    },
    title: {
      text: null
    },
    xAxis: {
      crosshair: {
        color: colors.labelsColor,
        zIndex: 5
      },
      labels: {
        format: '{value: %Y}',
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        y: 35
      },
      lineWidth: 0,
      maxPadding: 0,
      minPadding: 0,
      tickWidth: 0,
      type: 'datetime'
    },
    yAxis: {
      gridLineColor: `${colors.labelsColor}${hexOpacity[50]}`,
      labels: {
        formatter: ({ value }) => formatNumber(cultureCode, value, 0, 0),
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        x: -32
      },
      tickAmount: 6,
      title: {
        enabled: false
      }
    },
    tooltip: {
      enabled: isInteractive,
      crosshairs: true,
      shared: true,
      xDateFormat: '%b %Y',
      useHTML: true,
      headerFormat: `<span class='${classes.tooltipHeader}'>{point.key}</span><br>`,
      pointFormatter: function () {
        const value = this.series.options.custom.isMean
          ? formatNumber(cultureCode, this.y, 0, 2)
          : `${formatNumber(cultureCode, this.low, 0, 2)} - ${formatNumber(
              cultureCode,
              this.high,
              0,
              2
            )}`;

        return `
        <div class='${classes.tooltipFirstLine}'>${this.series.options.custom.type}</div>
        <div class='${classes.tooltipLastLine}'>${value}</div>
        `;
      },
      borderColor: `${colors.boundsColor}${hexOpacity[60]}`
    },
    plotOptions: {
      series: {
        enableMouseTracking: isInteractive,
        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series
  };

  return (
    <>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{
          style: {
            height: 281,
            width: isNil(width) ? 'auto' : `${width}px`
          }
        }}
        options={chartOptions}
      />

      <Legend
        items={[
          {
            title: i18n('expectedPathChart.default.expectedValue'),
            style: { backgroundColor: colors.expectedReturnColor }
          },
          {
            title: i18n('expectedPathChart.default.upperAndLowerBound'),
            style: { backgroundColor: `${colors.boundsColor}${hexOpacity[60]}` }
          }
        ]}
      />
    </>
  );
};

ExpectedPathChart.defaultProps = {
  isInteractive: true
};

export default ExpectedPathChart;
