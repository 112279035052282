import classNames from 'classnames';
import * as R from 'ramda';
import { Fragment } from 'react';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';

import sessionSelectors from '../../../shared/services/session/selectors';
import { getNumberInputFormat } from '../../../shared/utils/number';
import { BorderRadiusTypes } from '../../customerConfig/constants';
import { FieldComponentTypes, RuleTypes } from '../constants.js';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import DetalizedNumberInput from 'features/roboAdvice/adviceSession/detalizedNumberInput/components/index.js';
import { Layouts } from 'features/roboAdvice/shared/constants';
import { Spacing } from 'features/shared/constants/spacing';
import { getTranslation } from 'features/shared/utils/translations';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import FinalFormButtonSwitch from 'features/sharedModules/finalForm/components/buttonSwitch.js';
import FinalFormCheckboxes from 'features/sharedModules/finalForm/components/checkboxes';
import FinalFormDatepicker from 'features/sharedModules/finalForm/components/datepicker';
import FinalFormDropdown from 'features/sharedModules/finalForm/components/dropdown.js';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import FinalFormSelect from 'features/sharedModules/finalForm/components/select';
import FinalFormSwitch from 'features/sharedModules/finalForm/components/switch.js';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';
import FinalFormTextarea from 'features/sharedModules/finalForm/components/textarea';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '25px',
  [BorderRadiusTypes.sharp]: '15px'
};

const useStyles = createUseStyles(theme => ({
  field: {
    '& + &': {
      marginTop: Spacing.spacing01
    }
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 42
  },
  hasErrorWithPadding: {
    padding: Spacing.spacing01,
    borderRadius: borderRadius[theme.borderRadiusType],
    border: `2px solid ${theme.errorNegativeColor}`
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  },
  numberInput: {
    textAlign: 'right',

    '&::placeholder': {
      textAlign: 'left'
    }
  }
}));

const ConfigurableSelect = ({ name, config, fieldClassName, ...restProps }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <div className={classes.selectContainer}>
        <label id={`label-${name}`} htmlFor={`select-${name}`}>
          {config.label[getUserPageLanguage()]}
        </label>

        <FinalFormSelect
          ariaLabelledBy={`label-${name}`}
          id={`select-${name}`}
          name={name}
          options={config.options}
          width={155}
          menuHeightMax={200}
          {...restProps}
        />
      </div>
    </div>
  );
};

// TODO: cover by tests
const ConfigurableDropdown = ({
  name,
  config,
  fieldClassName,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const options = config.options || config.items;

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormDropdown
        name={name}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? config.label[getUserPageLanguage()]
            : i18n(config.labelKey) ||
              config.translations[getUserPageLanguage()]
        }
        data-testid={config.testId}
        options={R.map(
          o => ({
            key: o.key || o.activeValue,
            title: o.label
              ? o.label[getUserPageLanguage()]
              : config.preventTranslations
              ? o.titleKey
              : i18n(o.titleKey),
            isNoteActive: o.isNoteActive,
            textField: o.textField
          }),
          options
        )}
        inputProps={{
          placeholder: config.placeholder
            ? config.placeholder[getUserPageLanguage()]
            : i18n(config.placeholderKey)
        }}
        layout={config.layout}
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConfigurableTextInput = ({
  name,
  config,
  fieldClassName,
  children,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormTextInput
        name={name}
        inputType={config.inputType}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? config.label[getUserPageLanguage()]
            : i18n(config.labelKey) ||
              config.translations?.[getUserPageLanguage()]
        }
        data-testid={config.testId}
        placeholder={
          config.placeholder
            ? config.placeholder[getUserPageLanguage()]
            : i18n(config.placeholderKey)
        }
        layout={config.layout || Layouts.horizontal}
        {...restProps}
      />
      {children}
    </div>
  );
};

// TODO: cover by tests
const ConfigurableSwitch = ({ name, config, fieldClassName, ...restProps }) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormSwitch
        name={name}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : i18n(config.labelKey) ||
              config.translations[getUserPageLanguage()]
        }
        data-testid={config.testId}
        layout={config.layout}
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConfigurableButtonSwitch = ({
  name,
  config,
  fieldClassName,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormButtonSwitch
        name={name}
        items={config.items}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? config.label[getUserPageLanguage()]
            : i18n(config.labelKey) ||
              config.translations[getUserPageLanguage()]
        }
        data-testid={config.testId}
        layout={config.layout}
        required={config.required}
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConfigurableRadioButtons = ({
  name,
  config,
  fieldClassName,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName, {
        [classes.hasErrorWithPadding]: restProps.hasError
      })}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormRadios
        name={name}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? config.label[getUserPageLanguage()]
            : i18n(config.labelKey) ||
              config.translations[getUserPageLanguage()]
        }
        data-testid={config.testId}
        items={R.map(
          o => ({
            activeValue: o.activeValue,
            label: o.label ? o.label[getUserPageLanguage()] : i18n(o.labelKey),
            textField: o.textField
          }),
          config.items
        )}
        layout={config.layout || Layouts.vertical}
        required={config.required}
        info={config.info && config.info[getUserPageLanguage()]}
        popupInfo={config.popupInfo && config.popupInfo[getUserPageLanguage()]}
        additionalErrorCondition={false}
        {...restProps}
      />
    </div>
  );
};

const ConfigurableCheckboxes = ({
  name,
  config,
  fieldClassName,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName, {
        [classes.hasErrorWithPadding]: restProps.hasError
      })}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormCheckboxes
        name={name}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? config.label[getUserPageLanguage()]
            : i18n(config.labelKey) ||
              config.translations[getUserPageLanguage()]
        }
        data-testid={config.testId}
        items={R.map(
          o => ({
            activeValue: o.activeValue,
            label: o.label ? o.label[getUserPageLanguage()] : i18n(o.labelKey),
            textField: o.textField
          }),
          config.items
        )}
        width={25}
        height={25}
        layout={config.layout || Layouts.vertical}
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConfigurableTextarea = ({
  name,
  config,
  sectionClassName,
  fieldClassName,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormTextarea
        name={name}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? config.label[getUserPageLanguage()]
            : i18n(config.labelKey) ||
              config.translations[getUserPageLanguage()]
        }
        data-testid={config.testId}
        placeholder={
          config.placeholder
            ? config.placeholder[getUserPageLanguage()]
            : i18n(config.placeholderKey)
        }
        required={config.required}
        sectionClassName={sectionClassName}
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConfigurableNumberInput = ({
  name,
  config,
  fieldClassName,
  className,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const inputThousandSeparator = restProps.format
    ? ''
    : restProps.thousandSeparator || thousandSeparator;

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormNumberInput
        name={name}
        placeholder={
          config.placeholder
            ? config.placeholder[getUserPageLanguage()]
            : i18n(config.placeholderKey)
        }
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? config.label[getUserPageLanguage()]
            : i18n(config.labelKey) ||
              config.translations[getUserPageLanguage()]
        }
        format={config.format}
        allowLeadingZeros={config.allowLeadingZeros}
        data-testid={config.testId}
        required={config.required}
        className={classNames(classes.numberInput, className)}
        thousandSeparator={inputThousandSeparator}
        decimalSeparator={decimalSeparator}
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConfigurableDetalizedNumberInput = ({
  name,
  config,
  fieldClassName,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <DetalizedNumberInput
        name={name}
        data-testid={config.testId}
        required={config.required}
        isErrorMessageHidden
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConfigurableDatePicker = ({
  name,
  config,
  fieldClassName,
  ...restProps
}) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div
      className={classNames(classes.field, fieldClassName)}
      id={restProps.elementId || `input-${name}`}
    >
      <FinalFormDatepicker
        name={name}
        label={
          !R.isNil(config.labelValue)
            ? config.labelValue
            : config.label
            ? getTranslation(config.label)
            : i18n(config.labelKey) || getTranslation(config.translations)
        }
        data-testid={config.testId}
        placeholder={
          config.placeholder
            ? config.placeholder[getUserPageLanguage()]
            : i18n(config.placeholderKey)
        }
        layout={Layouts.horizontal}
        dateFormat={config.dateFormat}
        dateType={config.dateType}
        allowedYearsRange={config.allowedYearsRange}
        {...restProps}
      />
    </div>
  );
};

// TODO: cover by tests
const ConditionalRender = ({ rule, children }) => {
  if (rule.type === RuleTypes.allFieldsEqual) {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          if (
            R.all(f => R.path(R.split('.', f[0]), values) === f[1], rule.fields)
          ) {
            return children();
          }

          return null;
        }}
      </FormSpy>
    );
  }

  if (rule.type === RuleTypes.fieldChecked) {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          if (
            R.all(
              f => R.includes(f[1], R.path(R.split('.', f[0]), values) || []),
              rule.fields
            )
          ) {
            return children();
          }

          return null;
        }}
      </FormSpy>
    );
  }

  if (rule.type === RuleTypes.buttonSwitchChecked) {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          if (R.all(f => R.path(R.split('.', f[0]), values), rule.fields)) {
            return children();
          }

          return null;
        }}
      </FormSpy>
    );
  }

  if (rule.type === RuleTypes.buttonSwitchUnchecked) {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          if (
            R.all(
              f => R.path(R.split('.', f[0]), values) === false,
              rule.fields
            )
          ) {
            return children();
          }

          return null;
        }}
      </FormSpy>
    );
  }

  return null;
};

// TODO: cover by tests
export const ConfigurableComponent = ({ name, config, ...restProps }) => {
  let component = null;

  if (config.componentType === FieldComponentTypes.dropdown) {
    component = (
      <ConfigurableDropdown name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.textInput) {
    component = (
      <ConfigurableTextInput name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.switch) {
    component = (
      <ConfigurableSwitch name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.buttonSwitch) {
    component = (
      <ConfigurableButtonSwitch name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.radioButtons) {
    component = (
      <ConfigurableRadioButtons name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.checkboxes) {
    component = (
      <ConfigurableCheckboxes name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.textarea) {
    component = (
      <ConfigurableTextarea name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.datepicker) {
    component = (
      <ConfigurableDatePicker name={name} config={config} {...restProps} />
    );
  }

  if (config.componentType === FieldComponentTypes.detalizedNumberInput) {
    component = (
      <ConfigurableDetalizedNumberInput
        name={name}
        config={config}
        {...restProps}
      />
    );
  }

  if (config.componentType === FieldComponentTypes.numberInput) {
    component = (
      <ConfigurableNumberInput
        name={name}
        config={config}
        format={config.format}
        allowLeadingZeros={config.allowLeadingZeros}
        {...restProps}
      />
    );
  }

  if (config.componentType === FieldComponentTypes.select) {
    component = (
      <ConfigurableSelect name={name} config={config} {...restProps} />
    );
  }

  if (!R.isNil(config?.when)) {
    return (
      <ConditionalRender rule={config.when}>
        {() => component}
      </ConditionalRender>
    );
  }

  return component;
};

// TODO: cover by tests
export const RenderFields = ({ fields, adjustElement, ...restProps }) => (
  <Fragment>
    {R.map(field => {
      const element = (
        <ConfigurableComponent
          key={field[0]}
          name={field[0]}
          config={field[1].render}
          {...restProps}
        />
      );

      return adjustElement(field, element);
    }, fields)}
  </Fragment>
);

RenderFields.defaultProps = {
  adjustElement: (field, element) => element
};
