import classNames from 'classnames';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { InstrumentSelected } from 'features/roboAdvice/adviceSession/form/services/form';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { CustomAttribute } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { CustomAttributeFromConfig } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import Icon from 'features/shared/components/icon';
import NumberInput from 'features/shared/components/numberInput/numberInput';
import TableBodyRow from 'features/shared/components/table/tableBodyRow';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  rowCell: {
    padding: '1.2rem 1.3rem'
  },
  deleteButton: {
    cursor: 'pointer'
  },
  numberCell: {
    textAlign: 'right',
    maxWidth: '16rem'
  },
  numberInput: {
    width: '100%',
    textAlign: 'right'
  },
  center: {
    textAlign: 'center'
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  }
}));

type Props = {
  item: InstrumentSelected;
  index: number;
  instrumentsSelected: FieldArrayRenderProps<InstrumentSelected, HTMLElement>;
  decimalSeparator?: string;
  thousandSeparator?: string;
  productAttributes: CustomAttributeFromConfig[];
  customAttributeData?: CustomAttribute;
  showISINColumn: boolean;
  showAssetClassColumn: boolean;
};

const ExistingRow = ({
  item,
  index,
  instrumentsSelected,
  decimalSeparator,
  thousandSeparator,
  productAttributes,
  customAttributeData,
  showISINColumn,
  showAssetClassColumn
}: Props) => {
  const classes = useStyles();

  const deleteInstrument = index => {
    instrumentsSelected.fields.remove(index);
  };

  return (
    <AnyTableBodyRow id={item.id} isCssParity>
      {({ bodyRowCellClassName }) => (
        <>
          <td className={classNames(classes.rowCell, bodyRowCellClassName)}>
            {item.name}
          </td>
          {showISINColumn && (
            <td className={classNames(classes.rowCell, bodyRowCellClassName)}>
              {item.isin}
            </td>
          )}
          {showAssetClassColumn && (
            <td className={classNames(classes.rowCell, bodyRowCellClassName)}>
              {item.subAssetClass}
            </td>
          )}

          {productAttributes &&
            productAttributes.map(({ type, name }) => {
              if (type === ProductAttributeType.text) {
                return (
                  <td
                    className={classNames(
                      classes.rowCell,
                      bodyRowCellClassName
                    )}
                    key={name}
                  >
                    {customAttributeData?.productAttributeText?.[name]}
                  </td>
                );
              }

              if (type === ProductAttributeType.binary) {
                return (
                  <td
                    className={classNames(
                      classes.rowCell,
                      bodyRowCellClassName,
                      classes.center
                    )}
                    key={name}
                  >
                    {customAttributeData?.productAttributeBinary?.[name] ? (
                      <CheckIcon className={classes.checkIcon} />
                    ) : (
                      <CrossIcon className={classes.crossIcon} />
                    )}
                  </td>
                );
              }

              return null;
            })}

          <td
            className={classNames(
              classes.rowCell,
              classes.numberCell,
              bodyRowCellClassName
            )}
          >
            <NumberInput
              value={item.initialDeposit}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowNegative={false}
              decimalScale={2}
              className={classes.numberInput}
              placeholder="0"
              onChange={val => {
                instrumentsSelected.fields.update(index, {
                  ...item,
                  initialDeposit: val
                });
              }}
            />
          </td>

          <td
            className={classNames(
              classes.rowCell,
              classes.numberCell,
              bodyRowCellClassName
            )}
          >
            <NumberInput
              value={item.monthlySaving}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowNegative={false}
              decimalScale={2}
              className={classes.numberInput}
              placeholder="0"
              onChange={val => {
                instrumentsSelected.fields.update(index, {
                  ...item,
                  monthlySaving: val
                });
              }}
            />
          </td>

          <td
            className={classNames(
              classes.rowCell,
              classes.deleteButton,
              bodyRowCellClassName
            )}
            onClick={() => deleteInstrument(index)}
          >
            <Icon type="delete" style={{ marginRight: '6px' }} />
          </td>
        </>
      )}
    </AnyTableBodyRow>
  );
};

export default ExistingRow;
