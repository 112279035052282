import Tooltip from '../tooltip';
import Icon from 'features/shared/components/icon/index.js';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  labelWrap: {
    margin: 0,
    padding: 0,
    display: 'inline'
  },
  infoIcon: {
    color: theme.secondaryColor,
    fontSize: Typography.heading1.size
  },
  requiredMark: {
    display: 'inline',
    fontWeight: FontWeights.medium,
    color: theme.errorNegativeColor
  },
  labelInfoParagraph: {
    display: 'block',
    marginTop: '8px',
    lineHeight: Typography.body3.lineHeight,
    fontSize: Typography.body3.size,
    color: theme.secondaryColor
  }
}));

type Props = {
  label: string;
  required?: boolean;
  info?: string | null;
  popupInfo?: string | null;
};

const ConfigurableLabel = ({ label, required, info, popupInfo }: Props) => {
  const styles = useStyles();

  return (
    <>
      <p className={styles.labelWrap}>
        {label}
        {required && <span className={styles.requiredMark}>&nbsp;*&nbsp;</span>}
        {popupInfo && (
          <Tooltip
            content={popupInfo}
            infoTooltip
            trigger={<Icon className={styles.infoIcon} type="info" />}
            triggerButtonStyles={{
              display: 'inline-flex',
              verticalAlign: 'middle',
              marginLeft: 10
            }}
          />
        )}
      </p>
      {info && <span className={styles.labelInfoParagraph}>{info}</span>}
    </>
  );
};

export default ConfigurableLabel;
