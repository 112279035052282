import { useIsSessionReadOnly } from '../../main/services/selectors';
import { AvailablePortfoliosModes } from '../../shared/constants';
import {
  useAvailablePortfolios,
  usePortfolioRisk
} from '../../shared/services/availablePortfoliosSelectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { Dropdown } from 'features/sharedModules/finalForm/components/dropdown';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  onePortfolioLabel: {
    display: 'inline-block',
    background: theme.inputFillFocusColor,
    borderRadius: 20,
    padding: '10px 16px'
  }
}));

type Props = {
  goalId: string;
  onChange: (value: string) => void;
  value?: string | null;
};

const AnyDropdown = Dropdown as any;

const RiskModel = ({ goalId, onChange, value }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    advisoryComponents: { availablePortfoliosMode }
  } = useCustomerConfig();

  const options = useAvailablePortfolios(goalId);
  const portfolioRisk = usePortfolioRisk(goalId);

  return availablePortfoliosMode ===
    AvailablePortfoliosModes.linkedToFinalScore || options.length === 1 ? (
    <div className={classes.onePortfolioLabel}>{portfolioRisk?.title}</div>
  ) : (
    <AnyDropdown
      data-testid={'portfolios-dropdown'}
      disabled={isSessionReadOnly}
      inputProps={{
        placeholder: i18n('roboAdvice.advisory.buildPortfolio.selectPortfolio')
      }}
      onChange={onChange}
      value={value}
      options={options}
      doPreventInputFocusLoss={false}
    />
  );
};

export default RiskModel;
