import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { isEmpty, isNil, mergeRight } from 'ramda';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import Icon from '../../../../shared/components/icon';
import { defaultConfirmation } from '../../../../sharedInstances/defaultConfirmation';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { usePortfolioChartStore } from '../../portfolioChart';
import { useDeleteGoal } from '../../purposeAndRisk/components/useDeleteGoal';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { useGoalsStore, selectors } from '../../shared/services/goalsStore';
import { usePageStore, pagesToUpdate } from '../services/pageStore';
import { getHorizonYearsDisplayValue } from '../services/selectors';
import { riskScoreToNumber } from 'features/roboAdvice/adviceSession/riskScore/services/shared';
import Button from 'features/shared/components/button';
import GoalTableIcon from 'features/shared/components/icon/goalTableIcon';
import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import TextInput from 'features/shared/components/textInput/index';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  formatNumber,
  getNumberInputFormat
} from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  goalIconCell: {
    width: '56px',
    textAlign: 'center',
    fill: theme.accentColor,

    '& svg': {
      verticalAlign: 'middle'
    },

    '&:first-child': {
      paddingLeft: '1.6rem'
    }
  },
  actionIcon: {
    cursor: 'pointer',
    padding: Spacing.spacing01,

    '&:last-child': {
      marginLeft: 0
    }
  },
  actionButtonsCell: {
    padding: 0,
    minWidth: '112px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '4rem'
  },
  calculateButton: {
    marginTop: '36px'
  },
  numberCell: {
    minWidth: '110px',
    textAlign: 'right'
  },
  textCell: {
    minWidth: '80px'
  }
}));

const GoalsTable = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { goals } = useGoalsStore();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const portfolioChartStore = usePortfolioChartStore();
  const pageStore = usePageStore();
  const deleteGoal = useDeleteGoal();
  const updateGoal = useUpdateGoal();
  const isLoading =
    pageStore.pageStatuses['readPortfolioChartData'] === PageStatuses.pending ||
    pageStore.pageStatuses['readProposalPageData'] === PageStatuses.pending ||
    pageStore.pageStatuses['readProposalPageData'] === PageStatuses.ready;
  const totalExpectedValue = useGoalsStore(selectors.getTotalExpectedValue);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const [editedGoals, setEditedGoals] = React.useState([]);
  const {
    roboAdviceForm: {
      proposal: { goalsTableColumns },
      purposeAndRisk: { showGoalIcons }
    }
  } = useCustomerConfig();
  const { timeHorizonConfig } = useCustomerConfig();
  const isSessionReadOnly = useIsSessionReadOnly();

  const totalValues = React.useMemo(() => {
    const goalsAndEditedGoals = [
      ...goals.map(
        ({ goalId, data: { monthlyDeposit = 0, firstDeposit = 0 } }) => ({
          goalId,
          monthlyDeposit,
          firstDeposit
        })
      ),
      ...editedGoals.map(
        ({ goalId, data: { monthlyDeposit = 0, firstDeposit = 0 } }) => ({
          goalId,
          monthlyDeposit,
          firstDeposit
        })
      )
    ];

    const mergedGoals = goalsAndEditedGoals.reduce((acc, curr) => {
      const index = acc.findIndex(({ goalId }) => goalId === curr.goalId);
      if (index !== -1) {
        return acc.map((goal, i) =>
          i === index ? mergeRight(goal, curr) : goal
        );
      }
      return [...acc, curr];
    }, []);

    const totalFirstDeposit = mergedGoals.reduce(
      (acc, current) => acc + (current.firstDeposit || 0),
      0
    );

    const totalMonthlyDeposit = mergedGoals.reduce(
      (acc, current) => acc + (current.monthlyDeposit || 0),
      0
    );

    return {
      totalFirstDeposit: totalFirstDeposit || null,
      totalMonthlyDeposit: totalMonthlyDeposit || null
    };
  }, [editedGoals, goals]);

  const getExpectedAnnualReturn = (goalId, isPortfolioCustom) => {
    return portfolioChartStore.getChartData(goalId, isPortfolioCustom)
      ?.expectedAnnualReturn;
  };

  const getExpectedVolatility = (goalId, isPortfolioCustom) => {
    return portfolioChartStore.getChartData(goalId, isPortfolioCustom)
      ?.expectedVolatility;
  };

  const getEquityPortion = (goalId, isPortfolioCustom) => {
    return portfolioChartStore.getChartData(goalId, isPortfolioCustom)
      ?.equityPortion;
  };

  const headers = [
    {
      title: i18n('roboAdvice.purposeAndRisk.purposeTable.goal'),
      name: 'goalName',
      className: classes.textCell
    },
    {
      title: i18n('roboAdvice.purposeAndRisk.purposeTable.years'),
      name: 'years',
      className: classes.textCell
    },
    {
      title: i18n('roboAdvice.advisory.portfolioTable.riskScore'),
      name: 'riskScore',
      className: classes.numberCell
    },
    {
      title: i18n('roboAdvice.advisory.portfolioTable.monthlyDeposit'),
      name: 'monthlyDeposit',
      className: classes.numberCell
    },
    {
      title: i18n('roboAdvice.advisory.portfolioTable.firstDeposit'),
      name: 'firstDeposit',
      className: classes.numberCell
    },
    {
      title: i18n('roboAdvice.proposal.expectedAnnualReturn'),
      name: 'expectedAnnualReturn',
      className: classes.numberCell
    },
    {
      title: i18n('roboAdvice.proposal.expectedVolatility'),
      name: 'expectedVolatility',
      className: classes.numberCell
    },
    {
      title: i18n('roboAdvice.proposal.equityPortion'),
      name: 'equityPortion',
      className: classes.numberCell
    },
    {
      title: i18n('roboAdvice.proposal.expectedValue'),
      name: 'expectedValueInYears',
      className: classes.numberCell
    },
    {}
  ].filter(el => isEmpty(el) || goalsTableColumns[el.name]);

  const onDeleteGoalClick = async goalId => {
    await deleteGoal(goalId);
  };

  const handleValueChange = debounce((goalId, name, values) => {
    setEditedGoals(
      editedGoals.map(editedGoal => {
        if (editedGoal.goalId === goalId) {
          return {
            ...editedGoal,
            data: { ...editedGoal.data, [name]: values.floatValue }
          };
        }
        return editedGoal;
      })
    );
    return values.floatValue;
  }, 300);

  const editGoal = goal => {
    setEditedGoals([...editedGoals, goal]);
  };

  const editedGoalsIds = React.useMemo(
    () => editedGoals.map(({ goalId }) => goalId),
    [editedGoals]
  );

  const calculate = async () => {
    const goalsToEdit = editedGoals.filter(goal => {
      const storeGoal = goals.find(({ goalId }) => goal.goalId === goalId);
      return (
        goal.data.monthlyDeposit !== storeGoal.data.monthlyDeposit ||
        goal.data.firstDeposit !== storeGoal.data.firstDeposit
      );
    });

    setEditedGoals([]);

    for (const goal of goalsToEdit) {
      await updateGoal(goal.goalId, {
        data: {
          firstDeposit: goal.data.firstDeposit,
          monthlyDeposit: goal.data.monthlyDeposit
        }
      });
    }

    pageStore.setGoalsToUpdate('readProposalPageData', goalsToEdit);
    pageStore.setPageStatus('readProposalPageData', PageStatuses.ready);

    pagesToUpdate.forEach(p => {
      if (pageStore.pageStatuses[p] === PageStatuses.succeed) {
        pageStore.setGoalsToUpdate(p, goalsToEdit);
        pageStore.setPageStatus(p, PageStatuses.ready);
      }
    });
  };

  const isCalculateEnabled = React.useMemo(() => {
    return editedGoals.some(goal => {
      const storeGoal = goals.find(({ goalId }) => goal.goalId === goalId);
      return (
        goal.data.monthlyDeposit !== storeGoal.data.monthlyDeposit ||
        goal.data.firstDeposit !== storeGoal.data.firstDeposit
      );
    });
  }, [editedGoals, goals]);

  return (
    <>
      <TableComponent
        header={showGoalIcons ? [{}, ...headers] : headers}
        externalClasses={{ headerRowCell: classes.cell }}
        isLoading={isLoading}
      >
        <TableBody>
          {!isLoading &&
            goals.map(goal => (
              <TableBodyRow key={goal.goalId}>
                {({ bodyRowCellClassName }) => (
                  <>
                    {showGoalIcons && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.goalIconCell
                        )}
                      >
                        <GoalTableIcon icon={goal.icon} name={goal.name} />
                      </td>
                    )}

                    {goalsTableColumns.goalName && (
                      <td className={bodyRowCellClassName}>{goal.name}</td>
                    )}
                    {goalsTableColumns.years && (
                      <td className={bodyRowCellClassName}>
                        {getHorizonYearsDisplayValue({
                          timeHorizonConfig,
                          timeHorizon: goal.data.timeHorizon,
                          i18n
                        })}
                      </td>
                    )}
                    {goalsTableColumns.riskScore && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell
                        )}
                      >
                        {riskScoreToNumber(goal.data.riskScore)}
                      </td>
                    )}
                    {goalsTableColumns.monthlyDeposit && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell
                        )}
                      >
                        {editedGoalsIds.includes(goal.goalId) ? (
                          <NumberFormat
                            customInput={TextInput}
                            value={
                              editedGoals.find(
                                ({ goalId }) => goalId === goal.goalId
                              ).data.monthlyDeposit
                            }
                            onValueChange={values => {
                              handleValueChange(
                                goal.goalId,
                                'monthlyDeposit',
                                values
                              );
                            }}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            allowedDecimalSeparators={[',', '.']}
                            allowNegative={false}
                            decimalScale={0}
                            style={{ maxWidth: '150px', textAlign: 'right' }}
                          />
                        ) : (
                          formatNumber(
                            cultureCode,
                            goal.data.monthlyDeposit,
                            0,
                            0
                          )
                        )}
                      </td>
                    )}
                    {goalsTableColumns.firstDeposit && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell
                        )}
                      >
                        {editedGoalsIds.includes(goal.goalId) ? (
                          <NumberFormat
                            customInput={TextInput}
                            value={
                              editedGoals.find(
                                ({ goalId }) => goalId === goal.goalId
                              ).data.firstDeposit
                            }
                            onValueChange={values => {
                              handleValueChange(
                                goal.goalId,
                                'firstDeposit',
                                values
                              );
                            }}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            allowedDecimalSeparators={[',', '.']}
                            allowNegative={false}
                            decimalScale={0}
                            style={{ maxWidth: '150px', textAlign: 'right' }}
                          />
                        ) : (
                          formatNumber(
                            cultureCode,
                            goal.data.firstDeposit,
                            0,
                            0
                          )
                        )}
                      </td>
                    )}
                    {goalsTableColumns.expectedAnnualReturn && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell
                        )}
                      >
                        {isNil(
                          getExpectedAnnualReturn(
                            goal.goalId,
                            goal.data.isPortfolioCustom
                          )
                        )
                          ? ''
                          : `${formatNumber(
                              cultureCode,
                              getExpectedAnnualReturn(
                                goal.goalId,
                                goal.data.isPortfolioCustom
                              ),
                              0,
                              1
                            )}%`}
                      </td>
                    )}
                    {goalsTableColumns.expectedVolatility && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell
                        )}
                      >
                        {isNil(
                          getExpectedVolatility(
                            goal.goalId,
                            goal.data.isPortfolioCustom
                          )
                        )
                          ? ''
                          : `${formatNumber(
                              cultureCode,
                              getExpectedVolatility(
                                goal.goalId,
                                goal.data.isPortfolioCustom
                              ),
                              0,
                              1
                            )}%`}
                      </td>
                    )}
                    {goalsTableColumns.equityPortion && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell
                        )}
                      >
                        {isNil(
                          getEquityPortion(
                            goal.goalId,
                            goal.data.isPortfolioCustom
                          )
                        )
                          ? ''
                          : `${formatNumber(
                              cultureCode,
                              getEquityPortion(
                                goal.goalId,
                                goal.data.isPortfolioCustom
                              ),
                              0,
                              1
                            )}%`}
                      </td>
                    )}
                    {goalsTableColumns.expectedValueInYears && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell
                        )}
                      >
                        {isNil(goal.expectedValue)
                          ? ''
                          : formatNumber(cultureCode, goal.expectedValue, 0, 0)}
                      </td>
                    )}
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.actionButtonsCell
                      )}
                    >
                      {!isSessionReadOnly && (
                        <>
                          <Icon
                            type="delete"
                            className={classes.actionIcon}
                            onClick={() => {
                              const defaultConfirmationStore =
                                defaultConfirmation.useConfirmationStore.getState();
                              defaultConfirmationStore.open(
                                i18n(
                                  'roboAdvice.purposeAndRisk.purposeTable.deleteConfirmationMessage'
                                ),
                                () => {
                                  onDeleteGoalClick(goal.goalId);
                                }
                              );
                            }}
                          />
                          {editedGoalsIds.includes(goal.goalId) ? (
                            <Icon
                              className={classes.actionIcon}
                              type="close"
                              onClick={() =>
                                setEditedGoals(
                                  editedGoals.filter(
                                    ({ goalId: id }) => id !== goal.goalId
                                  )
                                )
                              }
                            />
                          ) : (
                            <Icon
                              className={classes.actionIcon}
                              type="edit"
                              onClick={() => editGoal(goal)}
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </TableBodyRow>
            ))}
          {goals.length > 1 && !isLoading && (
            <TableBodyRow>
              {({ bodyRowCellClassName }) => (
                <>
                  {showGoalIcons && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.goalIconCell
                      )}
                    ></td>
                  )}
                  {goalsTableColumns.goalName && (
                    <td className={bodyRowCellClassName}>
                      {i18n('shared.aggregatedPortfolio')}
                    </td>
                  )}
                  {goalsTableColumns.years && (
                    <td className={bodyRowCellClassName}></td>
                  )}
                  {goalsTableColumns.riskScore && (
                    <td className={bodyRowCellClassName}></td>
                  )}
                  {goalsTableColumns.monthlyDeposit && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(
                        cultureCode,
                        totalValues.totalMonthlyDeposit,
                        0,
                        0
                      )}
                    </td>
                  )}
                  {goalsTableColumns.firstDeposit && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(
                        cultureCode,
                        totalValues.totalFirstDeposit,
                        0,
                        0
                      )}
                    </td>
                  )}
                  {goalsTableColumns.expectedAnnualReturn && (
                    <td className={bodyRowCellClassName}></td>
                  )}
                  {goalsTableColumns.expectedVolatility && (
                    <td className={bodyRowCellClassName}></td>
                  )}
                  {goalsTableColumns.equityPortion && (
                    <td className={bodyRowCellClassName}></td>
                  )}
                  {goalsTableColumns.expectedValueInYears && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.numberCell
                      )}
                    >
                      {isNil(totalExpectedValue)
                        ? ''
                        : formatNumber(cultureCode, totalExpectedValue, 0, 0)}
                    </td>
                  )}
                  <td className={bodyRowCellClassName}></td>
                </>
              )}
            </TableBodyRow>
          )}
        </TableBody>
      </TableComponent>

      <div className={classes.buttonContainer}>
        {!!editedGoals.length && (
          <Button
            disabled={!isCalculateEnabled}
            className={classes.calculateButton}
            onClick={calculate}
          >
            {i18n('shared.calculate')}
          </Button>
        )}
      </div>
    </>
  );
};

export default GoalsTable;
