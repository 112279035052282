import ConfigurableLabel from '../../../../shared/components/configurableLabel';
import InputErrorMessage from '../../../../sharedModules/errors/components/inputErrorMessage';
import { ErrorMessage } from '../../../../sharedModules/errors/types';
import { createUseStyles } from '../../../../sharedModules/styles/components/styles';
import AdvancedSuitabilityTable from './advancedSuitabilityTable';
import { AdvancedSuitabilityTableHeader } from 'defaults/defaultRoboAdviceForm';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';

const useStyles = createUseStyles({
  container: {
    paddingTop: `${Spacing.spacing04}px`
  },
  title: {
    fontSize: Typography.heading1.size,
    fontWeight: FontWeights.medium,
    marginBottom: `${Spacing.spacing01}px`
  }
});

type Props = {
  name: string;
  header: string;
  checks: string[];
  options?: AdvancedSuitabilityTableHeader[];
  errors?: ErrorMessage[];
};

const SuitabilitySection = ({
  name,
  header,
  checks,
  options,
  errors
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container} id={`advancedSuitability-${name}`}>
      {errors?.map(error => (
        <InputErrorMessage message={error.alertMessage} />
      ))}
      <div className={classes.title}>
        <ConfigurableLabel label={header} required />
      </div>

      <AdvancedSuitabilityTable
        checks={checks}
        header={header}
        name={name}
        options={options}
        errors={errors}
      />
    </div>
  );
};

export default SuitabilitySection;
