import axios from 'axios';
import { isNil, mergeDeepRight } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import {
  createSessionGoal,
  getInvestorAccountsData,
  getSessionData,
  getSessionGoals,
  postSessionData
} from '../../../adviceSession/shared/api';
import { SessionNamespaces } from '../../../adviceSession/shared/constants';
import { getCurrentDate } from '../../../shared/utils';
import { createAdviceSession } from '../../main/api';
import { clientForm } from '../../main/services/clientForm';
import {
  mapClientAdviceSessionToServer,
  mapNewGoalsToHaveOnlyDataNeededForFollowUp,
  mapServerAdviceSessionToClient
} from '../services/mapping';
import {
  Detail,
  mapAccountsData
} from 'features/roboAdvice/adviceSession/financialSituation/services/mapping';
import { PortfolioType } from 'features/roboAdvice/adviceSession/portfolioChart';
import { readStandardPortfolio } from 'features/roboAdvice/adviceSession/proposal/components/portfolioChart/useReadPortfolioChartData';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { AssetClassAllocation } from 'features/roboAdvice/adviceSession/shared/portfolioChartSelectors';
import { BackendGoal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { CurrentUser } from 'features/shared/services/session/types';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export function useCreateFollowUpAdviceSession() {
  const { clientType, clientId } = useParams<AdviceSessionParams>();
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const currentUser: CurrentUser = useSelector(
    sessionSelectors.getCurrentUser()
  );
  const dispatch = useDispatch();
  const {
    roboAdviceForm: {
      financialSituation: {
        accounts: {
          sync: {
            mapping: {
              company: accountsSyncCompanyMapping,
              person: accountsSyncPersonMapping
            }
          },
          automaticTaggingOfAssetClassEnabled
        }
      }
    },
    roboAdvice: { subAssetClassNameMapping },
    roboPortfolioPrecision,
    tenantSettings: { fundNameForPresentation }
  } = useCustomerConfig();
  const { categories } = useSessionStore();
  const theme = useTheme();
  const portfolioChartColors = theme.chartPortfolioColors;

  const createFollowUpAdviceSession = async ({
    adviceSessionId,
    adviceSessionFollowUpId
  }: {
    adviceSessionId: string;
    adviceSessionFollowUpId: string;
  }) => {
    const clientFormState = clientForm.getState();

    const adviceSession = clientFormState.values.adviceSessions?.find(
      s => s.id === adviceSessionId
    );

    if (isNil(adviceSession) || isNil(adviceSession.name)) {
      return;
    }

    const inProgressNotificationId = v4();

    try {
      dispatch(
        notificationActionCreators.showNotification({
          id: inProgressNotificationId,
          isAutohideEnabled: false,
          message: i18n(
            'roboAdvice.client.followUpAdviceSessionInProgressMessage'
          ).replace('{0}', adviceSession.name),
          type: NotificationTypes.info
        })
      );

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const getSessionDataResponse = await getSessionData(
        accessToken,
        undefined,
        adviceSessionId
      );

      const sessionGoalsResponse = await getSessionGoals(
        accessToken,
        undefined,
        adviceSessionId
      );

      const { data: investorAccountData } = await getInvestorAccountsData(
        accessToken,
        undefined,
        clientId
      );

      if (investorAccountData.length === 0) {
        dispatch(
          notificationActionCreators.hideNotification(inProgressNotificationId)
        );
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.followUpAdviceSessionNoAccountsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        return;
      }

      const createAdviceSessionResponse = await createAdviceSession(
        accessToken,
        undefined,
        {
          ...mapClientAdviceSessionToServer(
            currentUser,
            clientId,
            adviceSession
          ),
          session_id: adviceSessionFollowUpId,
          name: `${adviceSession.name} - ${i18n(
            'roboAdvice.client.adviceSessionsTable.followUp'
          )}`
        }
      );

      const oldGoals = sessionGoalsResponse.data.data as BackendGoal[];

      const newGoals = mapNewGoalsToHaveOnlyDataNeededForFollowUp({
        goals: oldGoals
      });

      const newGoalsResponse = await Promise.all(
        newGoals.map(goal => {
          return createSessionGoal(accessToken, adviceSessionFollowUpId, {
            data: goal.data,
            name: goal.name,
            description: goal.description,
            icon: goal.icon
          });
        })
      );

      const accountsSyncMapping =
        clientType === ClientTypes.company
          ? accountsSyncCompanyMapping
          : accountsSyncPersonMapping;

      const modelPortfolios = [] as {
        newGoalId: string;
        originalGoalId: string;
        instrumentAllocation: AssetClassAllocation[];
      }[];
      const oldGoalsModelPortfolios = oldGoals.filter(
        goal => !goal.data.isPortfolioCustom
      );
      for (let index = 0; index < oldGoalsModelPortfolios.length; index++) {
        const goal = oldGoalsModelPortfolios[index];

        const modelPortfolioDetails = await readStandardPortfolio({
          qAuthAccessToken: accessToken,
          cancelTokenSource: undefined,
          risk_tolerance: goal.data?.portfolio,
          optionals: goal.data?.themes,
          precision: roboPortfolioPrecision,
          namespace_id: goal.data.productPlatformNamespace,
          portfolioChartColors,
          fundNameForPresentation,
          goalId: goal.goal_id,
          goalName: goal.name,
          goalIcon: goal.icon,
          subAssetClassNameMapping,
          portfolioType: PortfolioType.model
        });

        modelPortfolios.push({
          newGoalId: newGoalsResponse[index].data.data.goal_id,
          originalGoalId: goal.goal_id,
          instrumentAllocation:
            modelPortfolioDetails.dataSources.assetClassAllocation
              .instrumentAllocation
        });
      }

      await Promise.all(
        getSessionDataResponse.data.data
          .filter(
            namespaceData =>
              namespaceData.namespace !==
                SessionNamespaces.finishedSessionData &&
              namespaceData.namespace !==
                SessionNamespaces.finishedSessionInvestorData
          )
          .map(async namespaceData => {
            let additionalPayload = {};
            if (namespaceData.namespace === SessionNamespaces.sessionData) {
              additionalPayload = {
                ...additionalPayload,
                followUpId: adviceSessionId,
                followUpData: {
                  goals: sessionGoalsResponse.data.data.map((goal, index) => ({
                    newGoalId: newGoalsResponse[index].data.data.goal_id,
                    originalGoalId: goal.goal_id,
                    data: {
                      isPortfolioCustom: goal.data.isPortfolioCustom,
                      portfolio: goal.data.portfolio,
                      customPortfolio: goal.data.customPortfolio,
                      modelPortfolioInstrumentAllocation:
                        modelPortfolios.find(
                          mp => mp.originalGoalId === goal.goal_id
                        )?.instrumentAllocation || null
                    }
                  }))
                }
              };
            }
            if (namespaceData.namespace === SessionNamespaces.advisorInput) {
              additionalPayload = {
                ...additionalPayload,
                advisoryAdvisorNotes: undefined,
                proposalAdvisorNotes: undefined
              };

              // Financial situation override with new accounts data
              const financialSituationKey =
                clientType === ClientTypes.person
                  ? `personFinancialSituation`
                  : `companyFinancialSituation`;
              const financialSituationValues = namespaceData.payload[
                financialSituationKey
              ] as Record<string, Detail[]>;
              let newFinancialSituationAssets = await mapAccountsData({
                automaticTaggingOfAssetClassEnabled,
                investorAccountData,
                accountsSyncMapping,
                qAuthAccessToken: accessToken,
                cancelTokenSource: undefined,
                categories,
                financialSituationValues,
                subAssetClassNameMapping
              });

              // Tag follow-up assets from original session manual tagging
              if (financialSituationValues) {
                newFinancialSituationAssets = Object.entries(
                  newFinancialSituationAssets
                ).reduce((prev, [accountValueTarget, accountValues]) => {
                  const newAccountValues = accountValues.map(accountAsset => {
                    const foundManuallyTaggedAssetClass =
                      financialSituationValues[accountValueTarget].find(
                        asset => asset.title === accountAsset.title
                      )?.assetClass;

                    if (
                      accountAsset.assetClass ||
                      !foundManuallyTaggedAssetClass
                    ) {
                      return accountAsset;
                    }

                    return {
                      ...accountAsset,
                      assetClass: foundManuallyTaggedAssetClass,
                      isTaggedAutomatically: true
                    };
                  });

                  return {
                    ...prev,
                    [accountValueTarget]: newAccountValues
                  };
                }, {} as Record<string, Detail[]>);
              }

              Object.entries(newFinancialSituationAssets).forEach(
                ([accountValueTarget, syncValues]) => {
                  additionalPayload = {
                    ...additionalPayload,
                    [financialSituationKey]: {
                      [accountValueTarget]: [...syncValues, { id: v4() }]
                    }
                  };
                }
              );
            }

            return postSessionData(
              accessToken,
              undefined,
              adviceSessionFollowUpId,
              {
                namespace: namespaceData.namespace,
                payload: {
                  ...mergeDeepRight(namespaceData.payload, additionalPayload)
                }
              }
            );
          })
      );

      clientForm.mutators.push('adviceSessions', {
        ...mapServerAdviceSessionToClient(createAdviceSessionResponse.data),
        newTimestamp: getCurrentDate().getTime()
      });

      dispatch(
        notificationActionCreators.hideNotification(inProgressNotificationId)
      );
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.client.followUpAdviceSessionSuccessMessage'
          ).replace('{0}', adviceSession.name),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.hideNotification(inProgressNotificationId)
        );
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.followUpAdviceSessionErrorMessage'
            ).replace('{0}', adviceSession.name),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return createFollowUpAdviceSession;
}
