import { z } from 'zod';

import {
  fieldSchema,
  orderInformationSection,
  productAttributeSchema
} from './shared';

export const roboOrderFlowSchema = z.object({
  enabled: z.boolean(),
  namespaceId: z.number().nullable(),
  proposal: z.object({
    analyticsComponents: z.object({
      showExpectedValue: z.boolean(),
      showExpectedPath: z.boolean(),
      showCost: z.boolean()
    }),
    summaryTable: z.object({
      showExpectedAnnualReturn: z.boolean(),
      showExpectedValue: z.boolean(),
      productAttributeTxt1: productAttributeSchema.nullable(),
      productAttributeTxt2: productAttributeSchema.nullable(),
      productAttributeTxt3: productAttributeSchema.nullable(),
      productAttributeTxt4: productAttributeSchema.nullable(),
      productAttributeTxt5: productAttributeSchema.nullable(),
      productAttributeBinary1: productAttributeSchema.nullable(),
      productAttributeBinary2: productAttributeSchema.nullable(),
      productAttributeBinary3: productAttributeSchema.nullable(),
      productAttributeBinary4: productAttributeSchema.nullable(),
      productAttributeBinary5: productAttributeSchema.nullable()
    })
  }),
  orderInformationFields: z.array(fieldSchema),
  orderInformationPage2: z.object({
    person: z.object({
      enabled: z.boolean(),
      order: z.number().optional(),
      sections: z.array(orderInformationSection).optional(),
      advisorNotes: z
        .object({
          enabled: z.boolean(),
          required: z.boolean()
        })
        .optional()
    }),
    company: z.object({
      enabled: z.boolean(),
      order: z.number().optional(),
      sections: z.array(orderInformationSection).optional(),
      advisorNotes: z
        .object({
          enabled: z.boolean(),
          required: z.boolean()
        })
        .optional()
    })
  })
});

export type RoboOrderFlow = z.infer<typeof roboOrderFlowSchema>;

export const defaultRoboOrderFlow: RoboOrderFlow = {
  enabled: true,
  namespaceId: null,
  proposal: {
    analyticsComponents: {
      showExpectedValue: true,
      showExpectedPath: true,
      showCost: true
    },
    summaryTable: {
      showExpectedAnnualReturn: true,
      showExpectedValue: true,
      productAttributeTxt1: null,
      productAttributeTxt2: null,
      productAttributeTxt3: null,
      productAttributeTxt4: null,
      productAttributeTxt5: null,
      productAttributeBinary1: null,
      productAttributeBinary2: null,
      productAttributeBinary3: null,
      productAttributeBinary4: null,
      productAttributeBinary5: null
    }
  },
  orderInformationFields: [
    {
      name: 'orderInformation.investmentExperienceShares',
      label: {
        en: 'Investment experience shares',
        no: 'Investeringserfaring aksjer'
      },
      componentType: 'radioButtons',
      order: 1,
      required: true,
      isEnabled: true,
      items: [
        {
          activeValue: 'no',
          label: { en: 'No', no: 'Ingen' }
        },
        {
          activeValue: 'haveTraded',
          label: { en: 'Have traded', no: 'Har handlet' }
        },
        {
          activeValue: 'tradeActivity',
          label: { en: 'Trade activity', no: 'Handler aktivit' }
        }
      ]
    },
    {
      name: 'orderInformation.investmentExperienceCombinationFund',
      label: {
        en: 'Investment experience combination fund',
        no: 'Investeringserfaring kombinasjonsfond'
      },
      componentType: 'radioButtons',
      order: 2,
      required: true,
      isEnabled: true,
      items: [
        {
          activeValue: 'no',
          label: { en: 'No', no: 'Ingen' }
        },
        {
          activeValue: 'haveTraded',
          label: { en: 'Have traded', no: 'Har handlet' }
        },
        {
          activeValue: 'tradeActivity',
          label: { en: 'Trade activity', no: 'Handler aktivit' }
        }
      ]
    },
    {
      name: 'orderInformation.investmentExperienceFixedIncomeFunds',
      label: {
        en: 'Investment experience fixed income funds',
        no: 'Investeringserfaring rentefond'
      },
      componentType: 'radioButtons',
      order: 3,
      required: true,
      isEnabled: true,
      items: [
        {
          activeValue: 'no',
          label: { en: 'No', no: 'Ingen' }
        },
        {
          activeValue: 'haveTraded',
          label: { en: 'Have traded', no: 'Har handlet' }
        },
        {
          activeValue: 'tradeActivity',
          label: { en: 'Trade activity', no: 'Handler aktivit' }
        }
      ]
    },
    {
      name: 'orderInformation.investmentExperienceLifeSavings',
      label: {
        en: 'Investment experience life savings',
        no: 'Investeringserfaring livsparing'
      },
      componentType: 'radioButtons',
      order: 4,
      required: true,
      isEnabled: true,
      items: [
        {
          activeValue: 'no',
          label: { en: 'No', no: 'Ingen' }
        },
        {
          activeValue: 'haveTraded',
          label: { en: 'Have traded', no: 'Har handlet' }
        },
        {
          activeValue: 'tradeActivity',
          label: { en: 'Trade activity', no: 'Handler aktivit' }
        }
      ]
    },
    {
      name: 'orderInformation.investmentExperienceAlternativeInvestments',
      label: {
        en: 'Investment experience alternative investments',
        no: 'Investeringserfaringalternative investeringer'
      },
      componentType: 'radioButtons',
      order: 5,
      required: true,
      isEnabled: true,
      items: [
        {
          activeValue: 'no',
          label: { en: 'No', no: 'Ingen' }
        },
        {
          activeValue: 'haveTraded',

          label: { en: 'Have traded', no: 'Har handlet' }
        },
        {
          activeValue: 'tradeActivity',
          label: { en: 'Trade activity', no: 'Handler aktivit' }
        }
      ]
    }
  ],
  orderInformationPage2: {
    person: { enabled: false },
    company: { enabled: false }
  }
};
