import classNames from 'classnames';
import { isNil } from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Category, useSessionStore } from '../../session/services/sessionStore';
import {
  useAmountForAdviceValues,
  useFinancialSituationValues
} from '../services/selectors';
import { HoldingsSource } from './amountForAdvice/granularInformationModal';
import HiddenLabel from 'features/shared/components/label/hiddenLabel';
import SimpleTable from 'features/shared/components/table/simpleTable';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import FinalFormSelect from 'features/sharedModules/finalForm/components/select';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  headerCell: {
    color: theme.secondaryColor,
    padding: '17.5px 16px',
    fontWeight: FontWeights.normal,
    lineHeight: '150%'
  },
  right: {
    textAlign: 'right'
  },
  cell: {
    padding: '17.5px 16px',
    lineHeight: '150%'
  },
  tableTitle: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    margin: `${Spacing.spacing04}px 0 ${Spacing.spacing01}px`
  },
  tableTitleWithErrors: {
    marginTop: Spacing.spacing02
  }
}));

const FollowUpTable = ({ errors }: { errors: ErrorMessage[] }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const { clientType } = useParams<AdviceSessionParams>();
  const { data } = useAmountForAdviceValues();
  const financialSituationValues = useFinancialSituationValues();
  const { categories } = useSessionStore();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );

  const investmentPortfolioId = data?.find(
    field => field.source === HoldingsSource.internal
  )?.id;

  const instruments = useMemo(() => {
    const filteredInstrumentsBySource = financialSituationValues?.[
      investmentPortfolioId || ''
    ]
      ?.filter(({ accountNumber }) => !isNil(accountNumber))
      .map((instrument, index) => ({
        ...instrument,
        index
      }));

    filteredInstrumentsBySource.sort((a, b) => {
      if (a.isTaggedAutomatically && !b.isTaggedAutomatically) {
        return -1;
      }

      if (!a.isTaggedAutomatically && b.isTaggedAutomatically) {
        return 1;
      }

      return 0;
    });

    return filteredInstrumentsBySource;
  }, [financialSituationValues, investmentPortfolioId]);

  const assetClassOptions = useMemo(
    () =>
      categories
        .reduce((acc, curr) => {
          const index = acc.findIndex(
            ({ SubAssetClass }) => SubAssetClass === curr.SubAssetClass
          );
          return index === -1 ? [...acc, curr] : acc;
        }, [] as Category[])
        .map(category => ({ label: category.SubAssetClass, value: category })),
    [categories]
  );

  return (
    <>
      {errors?.map(error => (
        <InputErrorMessage message={error.alertMessage} key={error.elementId} />
      ))}

      <div
        className={classNames(classes.tableTitle, {
          [classes.tableTitleWithErrors]: !!errors?.length
        })}
      >
        {i18n('roboAdvice.advisory.portfolio.existingPortfolio')}
      </div>

      <SimpleTable
        headers={() => (
          <>
            <th className={classes.headerCell}>{i18n('shared.instrument')}</th>
            <th
              className={classNames(classes.headerCell, classes.right)}
              style={{ width: '10%' }}
            >
              {i18n('shared.value')}
            </th>
            <th className={classes.headerCell} style={{ width: '30%' }}>
              {i18n('shared.assetClass')}
            </th>
          </>
        )}
        body={({ rowClassName }) => (
          <>
            {instruments.map(instrument => (
              <tr key={instrument.id} className={rowClassName}>
                <td className={classes.cell}>{instrument.title}</td>

                <td className={classNames(classes.cell, classes.right)}>
                  {formatNumber(cultureCode, instrument.toAdvisory, 0, 2)}
                </td>

                {instrument.isTaggedAutomatically ? (
                  <td className={classNames(classes.cell)}>
                    {instrument.assetClass.SubAssetClass}
                  </td>
                ) : (
                  <td className={classes.cell}>
                    <HiddenLabel
                      id={`existing-portfolio-${instrument.id}`}
                      inputId={`existing-portfolio-input-${instrument.id}`}
                      text={i18n(
                        'roboAdvice.financialSituation.amountForAdvice.selectAssetClass'
                      )}
                    />

                    <FinalFormSelect
                      ariaLabelledBy={`existing-portfolio-${instrument.id}`}
                      id={`existing-portfolio-input-${instrument.id}`}
                      name={
                        clientType === ClientTypes.person
                          ? `personFinancialSituation.${investmentPortfolioId}.${instrument.index}.assetClass`
                          : `companyFinancialSituation.${investmentPortfolioId}.${instrument.index}.assetClass`
                      }
                      options={assetClassOptions}
                      placeholder={i18n(
                        'roboAdvice.financialSituation.amountForAdvice.selectAssetClass'
                      )}
                      hasError={
                        errors?.findIndex(
                          e =>
                            e.elementId ===
                            `existing-portfolio-input-${instrument.id}`
                        ) !== -1
                      }
                      error={
                        errors?.find(
                          e =>
                            e.elementId ===
                            `existing-portfolio-input-${instrument.id}`
                        )?.alertMessage
                      }
                    />
                  </td>
                )}
              </tr>
            ))}
          </>
        )}
        hasError={!!errors?.length}
      />
    </>
  );
};

export default FollowUpTable;
