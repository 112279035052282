import { isNil } from 'ramda';

import { SessionStatuses } from '../../../shared/constants';
import {
  AdviceSession,
  ClientFormState,
  useClientForm
} from '../../main/services/clientForm';
import {
  ClientPageStoreData,
  useClientPageStore
} from '../../main/services/clientPageStore';
import { mapChannelNameToTranslationKey } from '../services/mapping';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export type AdviceSessionView = {
  channelKey?: string;
  id: string;
  index: number;
  newTimestamp: number;
  name?: string;
  created?: string;
  lastActivityDate?: string;
  channel?: string;
  status?: string;
  originalStatus?: string;
  adviceType?: string | null;
};

export function useAdviceSessions() {
  return getAdviceSessionsResult({
    i18n: useI18n(),
    clientForm: useClientForm(),
    clientPageStore: useClientPageStore()
  });
}

const statusToTranslation = {
  [SessionStatuses.open]: 'roboAdvice.client.adviceSessionStatuses.ongoing',
  [SessionStatuses.complete]:
    'roboAdvice.client.adviceSessionStatuses.completed'
};

export function getAdviceSessionsResult({
  i18n,
  clientForm
}: {
  i18n: any;
  clientForm: ClientFormState;
  clientPageStore: ClientPageStoreData;
}) {
  const {
    values: { adviceSessions }
  } = clientForm;

  if (isNil(adviceSessions)) {
    return [];
  }

  return adviceSessions
    .map((s: AdviceSession, index: number) => {
      let status;

      const statusTranslation = isNil(s.status)
        ? undefined
        : statusToTranslation[s.status];
      status = isNil(statusTranslation) ? '' : i18n(statusTranslation);

      return {
        advisor: s.advisor,
        id: s.id,
        index,
        newTimestamp: s.newTimestamp || 0,
        name: s.name,
        created: s.created,
        lastActivityDate: s.lastActivityDate,
        channel: s.channel,
        channelKey: mapChannelNameToTranslationKey(s.channel),
        originalStatus: s.status,
        adviceType: s.adviceType,
        status
      };
    })
    .sort((a, b) => {
      if (a.newTimestamp > b.newTimestamp) {
        return -1;
      }
      if (a.newTimestamp < b.newTimestamp) {
        return 1;
      }
      if (a.lastActivityDate && b.lastActivityDate) {
        if (a.lastActivityDate > b.lastActivityDate) {
          return -1;
        }
        if (a.lastActivityDate < b.lastActivityDate) {
          return 1;
        }
      }
      return 0;
    });
}
