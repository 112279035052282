import create from 'zustand';

import { AccountData } from './shared/api/types';

export type Transaction = {
  action: string;
  aggregatedTargetValue: number;
  isin: string;
  keepOutsideOfAdvisory: 0;
  name: string;
  transactionValue: number;
  existingPortfolioValue?: number;
  toAdvisory?: number;
};

type TransactionListStoreData = {
  transactions: Transaction[];
  accountsData: AccountData[];
};

type TransactionListStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<TransactionListStoreData>) => void;
  setTransactions: (transactions: Transaction[]) => void;
  setAccountsData: (accountsData: AccountData[]) => void;
};

type TransactionListStoreState = TransactionListStoreData &
  TransactionListStoreApi;

const defaultData = {
  transactions: [],
  accountsData: []
};

export const useTransactionListStore = create<TransactionListStoreState>(
  set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setTransactions: transactions => {
      set(state => ({
        ...state,
        transactions
      }));
    },
    setAccountsData: accountsData => {
      set(state => ({
        ...state,
        accountsData
      }));
    }
  })
);
