import { isNil } from 'ramda';
import { useParams } from 'react-router-dom';

import { useReadProductChooserRecommendation } from '../../../roboAdvice/adviceSession/advisory/components/useReadProductChooserRecommendation';
import {
  calculateInternalPortfolioTotalValue,
  calculateMonthlySurplus
} from '../../../roboAdvice/adviceSession/advisory/services/mapping';
import {
  useAmountForAdviceValues,
  useFinancialSituationValues
} from '../../../roboAdvice/adviceSession/financialSituation/services/selectors';
import {
  getAdvisoryAdvisorNotesValidationError,
  useForm as useRoboAdviceForm
} from '../../../roboAdvice/adviceSession/form/services/form';
import { getGoalPortfolioValidationErrors } from '../../../roboAdvice/adviceSession/main/services/selectors';
import {
  Goal,
  useGoalsStore
} from '../../../roboAdvice/adviceSession/shared/services/goalsStore';
import { carouselIds } from '../../../shared/components/carousel/carouselStore';
import { getTranslation } from '../../../shared/utils/translations';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { AdvisoryErrors, ErrorMessage } from '../types';
import { getErrorMessageWithReplacedValues } from '../utils';
import { NamespaceStatus } from 'features/roboAdvice/adviceSession/advisory/types';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { ReadAnalyzeAdvancedSuitabilityStatusV2Response } from 'features/roboAdvice/adviceSession/shared/api/types';
import { AdviceSessionParams } from 'features/shared/constants/session';

export const useGetAdvisoryErrors = (): AdvisoryErrors => {
  const { goals } = useGoalsStore();
  const {
    advisoryComponents,
    roboAdviceForm: {
      financialSituation: { assets, isAmountForAdviceEnabled, accounts },
      errorMessagesEnabled
    },
    roboAdvice: {
      productChooserRecommandation: {
        requireComment,
        enabled: productChooserRecommandationEnabled
      },
      targetMarketAssessmentEnabled,
      targetMarketAssessmentBlockNotSuitable
    },
    analyticsComponents: { isTransactionListHidden }
  } = useCustomerConfig();
  const roboAdviceFormState = useRoboAdviceForm();
  const { clientType } = useParams<AdviceSessionParams>();
  const financialSituationValues = useFinancialSituationValues();
  const { currentSum, originalSum } = useAmountForAdviceValues();
  const i18n = useI18n();
  const readProductChooserRecommendation =
    useReadProductChooserRecommendation();
  const { targetMarketAssessmentData } = useKnowledgeAndExperienceStore();

  return {
    savingsPlan: getSavingsPlanError({
      goals,
      assets,
      accounts,
      clientType,
      financialSituationValues,
      currentSum,
      originalSum,
      isAmountForAdviceEnabled,
      isTransactionListHidden,
      i18n,
      errorMessagesEnabled
    }),
    allocation: getAllocationErrors({
      goals,
      i18n,
      errorMessagesEnabled
    }),
    productPlatformQuestions: getProductPlatformQuestionsErrors({
      goals,
      readProductChooserRecommendation,
      productChooserRecommandationEnabled,
      i18n,
      errorMessagesEnabled
    }),
    productPlatform: getProductPlatformErrors({
      goals,
      requireComment,
      readProductChooserRecommendation,
      productChooserRecommandationEnabled,
      i18n,
      errorMessagesEnabled
    }),
    assetClassAllocationAdvisorNotes: getAssetClassAdvisorNotesErrors({
      goals,
      advisoryComponents,
      roboAdviceFormState,
      i18n,
      errorMessagesEnabled
    }),
    fundAllocationAdvisorNotes: getFundAllocationAdvisorNotesErrors({
      goals,
      advisoryComponents,
      roboAdviceFormState,
      i18n,
      errorMessagesEnabled
    }),
    advisorNotes: getAdvisorNotesErrors({
      roboAdviceFormState,
      i18n,
      errorMessagesEnabled
    }),
    fundAllocationTargetMarketAssessment: getTargetMarketAssessmentErrors({
      goals,
      i18n,
      targetMarketAssessmentEnabled,
      targetMarketAssessmentBlockNotSuitable,
      errorMessagesEnabled,
      targetMarketAssessmentData
    })
  };
};

const getSavingsPlanError = ({
  goals,
  assets,
  accounts,
  clientType,
  financialSituationValues,
  currentSum,
  originalSum,
  isAmountForAdviceEnabled,
  isTransactionListHidden,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const savingsPlanErrors: ErrorMessage[] = [];

  if (!errorMessagesEnabled) {
    return savingsPlanErrors;
  }

  const goalWithError = goals.find(
    goal => !(goal.data.monthlyDeposit > 0 || goal.data.firstDeposit > 0)
  );
  if (goalWithError) {
    savingsPlanErrors.push({
      listMessage: i18n('roboAdvice.errors.missingSavingPlan').replace(
        '{0}',
        i18n('roboAdvice.advisory.savingsPlan')
      ),
      alertMessage: i18n('roboAdvice.errors.missingSavingPlanAlert'),
      elementId: 'savings-plan',
      data: { advisoryGoal: goalWithError.goalId }
    });
  }

  const firstDepositSum = goals.reduce(
    (acc, goal) => acc + (goal.data.firstDeposit || 0),
    0
  );

  let amountForAdvice: number | null = null;
  if (!isAmountForAdviceEnabled) {
    amountForAdvice = null;
  } else if (currentSum !== originalSum) {
    amountForAdvice = currentSum;
  } else if (originalSum === 0) {
    amountForAdvice = null;
  } else {
    amountForAdvice = originalSum;
  }

  const assetsFieldsName = assets[clientType]
    ?.filter(({ enabled }) => enabled)
    ?.map(({ id }) => id);

  let assetsSum = 0.0;

  if (assetsFieldsName?.length) {
    for (const field of assetsFieldsName) {
      const rows = financialSituationValues?.[field] || [];

      for (const value of rows) {
        const { toAdvisory, value: val = 0 } = value || {};
        assetsSum += toAdvisory || val;
      }
    }
  }

  const monthlySurplus = calculateMonthlySurplus(
    financialSituationValues?.monthlySurplus
  );

  const monthlyDepositSum = goals.reduce(
    (acc, goal) => acc + (goal.data.monthlyDeposit || 0),
    0
  );

  const internalHoldingSum = goals.reduce(
    (acc, goal) => acc + (goal.data.internalHolding || 0),
    0
  );

  const internalPortfolioTotalValue = !isTransactionListHidden
    ? calculateInternalPortfolioTotalValue({
        financialSituationValues,
        clientType,
        accountsSyncPersonMapping: accounts.sync.mapping.person,
        accountsSyncCompanyMapping: accounts.sync.mapping.company
      })
    : 0;
  if (
    !isTransactionListHidden &&
    !isNil(internalPortfolioTotalValue) &&
    internalPortfolioTotalValue !== internalHoldingSum
  ) {
    savingsPlanErrors.push({
      listMessage: i18n('roboAdvice.errors.internalHolding'),
      alertMessage: i18n('roboAdvice.errors.internalHoldingAlert'),
      elementId: 'savings-plan'
    });
  }
  if (
    (amountForAdvice !== null && firstDepositSum > amountForAdvice) ||
    (amountForAdvice === null && firstDepositSum > assetsSum) ||
    (!isNil(monthlySurplus) && monthlyDepositSum > monthlySurplus)
  ) {
    savingsPlanErrors.push({
      listMessage: i18n('roboAdvice.errors.savingPlan'),
      alertMessage: i18n('roboAdvice.errors.savingPlanAlert'),
      elementId: 'savings-plan'
    });
  }

  return savingsPlanErrors;
};

const getProductPlatformQuestionsErrors = ({
  goals,
  readProductChooserRecommendation,
  productChooserRecommandationEnabled,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  if (!errorMessagesEnabled) {
    return [];
  }

  const productPlatformQuestionsErrors: ErrorMessage[] = [];

  if (productChooserRecommandationEnabled) {
    goals.forEach(goal => {
      const { questions } = readProductChooserRecommendation({
        goalId: goal.goalId
      });

      questions
        .filter(({ enabled }) => enabled)
        .forEach(question => {
          if (
            isNil(goal.data?.productPlatformQuestions) ||
            isNil(goal.data?.productPlatformQuestions[question.name])
          ) {
            productPlatformQuestionsErrors.push({
              listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
                '{0}',
                getTranslation(question.label)
              ),
              alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
              elementId: 'product-chooser',
              data: { advisoryGoal: goal.goalId, productPlatformTab: 1 }
            });
          }
        });
    });
  }

  return productPlatformQuestionsErrors;
};

type GetProductPlatformErrorsParams = {
  goals: Goal[];
  requireComment: boolean;
  productChooserRecommandationEnabled: boolean;
  readProductChooserRecommendation: ReturnType<
    typeof useReadProductChooserRecommendation
  >;
  i18n: ReturnType<typeof useI18n>;
  errorMessagesEnabled: boolean;
};

const getProductPlatformErrors = ({
  goals,
  requireComment,
  productChooserRecommandationEnabled,
  readProductChooserRecommendation,
  i18n,
  errorMessagesEnabled
}: GetProductPlatformErrorsParams): ErrorMessage[] => {
  if (!errorMessagesEnabled) {
    return [];
  }

  const productPlatformErrors: ErrorMessage[] = [];

  if (productChooserRecommandationEnabled) {
    goals.forEach(goal => {
      const { items } = readProductChooserRecommendation({
        goalId: goal.goalId
      });
      if (
        requireComment &&
        items.find(i => i.activeValue === goal.data.productPlatformNamespace)
          ?.status !== NamespaceStatus.RECOMMENDED &&
        !goal.data?.productPlatformReasoning?.length &&
        !isNil(goal.data?.productPlatformNamespace)
      ) {
        productPlatformErrors.push({
          listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
            '{0}',
            i18n('roboAdvice.advisory.productPlatform.reasoningForSelection')
          ),
          alertMessage: i18n(
            'roboAdvice.errors.missingReasoningForProductPlatformSelection'
          ),
          elementId: 'product-chooser',
          data: { advisoryGoal: goal.goalId, productPlatformTab: 2 }
        });
      }
    });
  }

  return productPlatformErrors;
};

type GetAllocationErrorsParams = {
  goals: Goal[];
  i18n: ReturnType<typeof useI18n>;
  errorMessagesEnabled: boolean;
};

const getAllocationErrors = ({
  goals,
  i18n,
  errorMessagesEnabled
}: GetAllocationErrorsParams): ErrorMessage[] => {
  if (!errorMessagesEnabled) {
    return [];
  }

  return goals
    .map(goal => {
      const errors = getGoalPortfolioValidationErrors(goal);

      return errors.map(error => {
        const errorMessage = getErrorMessageWithReplacedValues(error, i18n);

        return {
          listMessage: errorMessage,
          alertMessage: errorMessage,
          elementId: 'portfolio-container',
          data: {
            advisoryGoal: goal.goalId,
            carouselState: {
              [carouselIds.customPortfolio]: {
                selectedItemIndex:
                  error === 'roboAdvice.errors.fundAllocationAlert' ? 1 : 0
              }
            }
          }
        };
      });
    })
    .flat();
};

const getAssetClassAdvisorNotesErrors = ({
  goals,
  advisoryComponents,
  roboAdviceFormState,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const isAssetClassAllocationAdvisorNotesEnabled =
    advisoryComponents.customPortfolio.assetClassAllocation.advisorNotes
      .enabled;
  const isAssetClassAllocationAdvisorNotesRequired =
    advisoryComponents.customPortfolio.assetClassAllocation.advisorNotes
      .required;
  if (
    !errorMessagesEnabled ||
    !isAssetClassAllocationAdvisorNotesEnabled ||
    !isAssetClassAllocationAdvisorNotesRequired
  ) {
    return [];
  }

  return goals
    .filter(({ data }) => data.isPortfolioCustom)
    .map(goal => {
      const errors: ErrorMessage[] = [];
      const advisorNoteValue =
        roboAdviceFormState.values.customPortfolio?.assetClassAllocation
          ?.advisorNotes[goal.goalId];

      if (isNil(advisorNoteValue) || advisorNoteValue === '') {
        errors.push({
          listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
            '{0}',
            i18n(
              'roboAdvice.advisory.customPortfolio.tabs.assetClassAllocation'
            )
          ),
          alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
          elementId: 'custom-portfolio-asset-class-allocation-advisor-notes',
          data: {
            advisoryGoal: goal.goalId,
            carouselState: {
              [carouselIds.customPortfolio]: { selectedItemIndex: 0 }
            }
          }
        });
      }
      return errors;
    })
    .flat();
};

const getFundAllocationAdvisorNotesErrors = ({
  goals,
  advisoryComponents,
  roboAdviceFormState,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const isFundAllocationAdvisorNotesEnabled =
    advisoryComponents.customPortfolio.fundAllocation.advisorNotes.enabled;
  const isFundAllocationAdvisorNotesRequired =
    advisoryComponents.customPortfolio.fundAllocation.advisorNotes.required;
  if (
    !errorMessagesEnabled ||
    !isFundAllocationAdvisorNotesEnabled ||
    !isFundAllocationAdvisorNotesRequired
  ) {
    return [];
  }

  return goals
    .filter(({ data }) => data.isPortfolioCustom)
    .map(goal => {
      const errors: ErrorMessage[] = [];
      const advisorNoteValue =
        roboAdviceFormState.values.customPortfolio?.fundAllocation
          ?.advisorNotes[goal.goalId];

      if (isNil(advisorNoteValue) || advisorNoteValue === '') {
        errors.push({
          listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
            '{0}',
            i18n('roboAdvice.advisory.customPortfolio.tabs.fundAllocation')
          ),
          alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
          elementId: 'custom-portfolio-fund-allocation-advisor-notes',
          data: {
            advisoryGoal: goal.goalId,
            carouselState: {
              [carouselIds.customPortfolio]: { selectedItemIndex: 1 }
            }
          }
        });
      }
      return errors;
    })
    .flat();
};

const getAdvisorNotesErrors = ({
  roboAdviceFormState,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  if (!errorMessagesEnabled) {
    return [];
  }
  const error = getAdvisoryAdvisorNotesValidationError(
    roboAdviceFormState.values?.advisoryAdvisorNotes
  );
  if (
    error ===
    'roboAdvice.advisory.advisorNotes.advisorNotesEmptyValidationError'
  ) {
    return [
      {
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0}',
          i18n('roboAdvice.advisory.reasoningForAdvice')
        ),
        alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
        elementId: 'advisory-advisor-notes'
      }
    ];
  } else if (
    error?.text ===
    'roboAdvice.advisory.advisorNotes.advisorNotesMinLengthValidationError'
  ) {
    return [
      {
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0}',
          i18n('roboAdvice.advisory.reasoningForAdvice')
        ),
        alertMessage: i18n(
          'roboAdvice.advisory.advisorNotes.advisorNotesMinLengthValidationError'
        ).replace('{0}', error.args[0]),
        elementId: 'advisory-advisor-notes'
      }
    ];
  }

  return [];
};

const getTargetMarketAssessmentErrors = ({
  goals,
  i18n,
  targetMarketAssessmentEnabled,
  targetMarketAssessmentBlockNotSuitable,
  errorMessagesEnabled,
  targetMarketAssessmentData
}: {
  goals: Goal[];
  i18n: ReturnType<typeof useI18n>;
  targetMarketAssessmentEnabled: boolean;
  targetMarketAssessmentBlockNotSuitable: boolean;
  errorMessagesEnabled: boolean;
  targetMarketAssessmentData: Record<
    string,
    ReadAnalyzeAdvancedSuitabilityStatusV2Response['data']
  >;
}): ErrorMessage[] => {
  if (
    !errorMessagesEnabled ||
    !targetMarketAssessmentEnabled ||
    !targetMarketAssessmentBlockNotSuitable ||
    !targetMarketAssessmentData
  ) {
    return [];
  }

  return goals
    .filter(({ data }) => data.isPortfolioCustom)
    .reduce<ErrorMessage[]>((errors, goal) => {
      const allInstruments =
        goal.data.customPortfolio?.flatMap(
          assetClass => assetClass.instruments
        ) ?? [];

      const hasUnsuitableInstruments = allInstruments.some(instrument => {
        const goalData = targetMarketAssessmentData[goal.goalId];
        if (!goalData) {
          return false;
        }

        const assessmentData = goalData.find(({ id }) => id === instrument.id);
        if (!assessmentData) {
          return false;
        }

        return (
          !assessmentData.productIsSuitable &&
          !assessmentData.productIsSuitableInPortfolio
        );
      });

      if (hasUnsuitableInstruments) {
        errors.push({
          listMessage: i18n(
            'roboAdvice.advisory.customPortfolio.targetMarketAssessmentError'
          ).replace('{0}', goal.name),
          alertMessage: i18n(
            'roboAdvice.advisory.customPortfolio.targetMarketAssessmentAlert'
          ),
          elementId: 'fund-allocation-table',
          data: {
            advisoryGoal: goal.goalId,
            carouselState: {
              [carouselIds.customPortfolio]: { selectedItemIndex: 1 }
            }
          }
        });
      }

      return errors;
    }, []);
};
