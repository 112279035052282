import { isNil, path as rPath } from 'ramda';

import { isFieldProgressCalculationRequired } from '../adviceSession/shared/services/progressSelectors';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

export const getCurrentDate = () => new Date();

export const getFieldsWithTextFields = (fields, values) => {
  return fields
    .map(adv => {
      const path = adv.name.split('.');

      return adv.items
        ? adv.items
            .filter(a => {
              const value = rPath(path, values);
              return (
                (Array.isArray(value)
                  ? value.includes(a.activeValue)
                  : a.activeValue === value) && a.textField
              );
            })
            .map(a => a.textField)
        : [];
    })
    .flat()
    .filter(
      f =>
        f.required &&
        (isNil(f.when) || isFieldProgressCalculationRequired(f.when, values))
    )
    .map(f => ({ ...f, additionalTextField: true }));
};

export const getDefaultErrorMessage = (
  componentType: keyof typeof FieldComponentTypes,
  additionalTextField: true | undefined
): string => {
  if (additionalTextField) {
    return 'shared.error.additionalTextField';
  }
  switch (componentType) {
    case FieldComponentTypes.textInput:
    case FieldComponentTypes.numberInput:
    case FieldComponentTypes.textarea:
      return 'shared.error.requiredTextField';
    case FieldComponentTypes.buttonSwitch:
    case FieldComponentTypes.radioButtons:
      return 'shared.error.requiredRadio';
    case FieldComponentTypes.checkboxes:
      return 'shared.error.requiredCheckbox';
    case FieldComponentTypes.dropdown:
    case FieldComponentTypes.countryDropdown:
      return 'shared.error.requiredDropdown';
    default:
      return '';
  }
};
