import { useForm as useRoboAdviceForm } from '../../../form/services/form';
import OrderExecutionActionButtons from '../orderExecutionActionButtons';
import OrderInformationSection from './orderInformationSection';
import { useInitPage } from './useInitPage';
import { RoboAdviceOrderExecutionPages } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { Spacing } from 'features/shared/constants/spacing';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useGetAllOrderExecutionErrors } from 'features/sharedModules/errors/services/useGetAllOrderExecutionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  errors: {
    marginTop: Spacing.spacing04
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  }
});

const OrderInformationPage2 = ({ onNavButtonClick }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const allErrors = useGetAllOrderExecutionErrors();
  const errors = allErrors[RoboAdviceOrderExecutionPages.orderInformationPage2];
  const { sections } = useInitPage();
  const {
    roboOrderFlow: { orderInformationPage2 }
  } = useCustomerConfig();
  const { values } = useRoboAdviceForm();
  const clientType = values?.clientInformation?.clientType;

  return (
    sections && (
      <>
        <ErrorsList
          pageName={getTranslation(
            orderInformationPage2[clientType]?.pageLabel
          )}
          errors={errors}
        />

        {sections.map(
          ({
            label,
            labelKey,
            showSum,
            sumLabel,
            showBackground,
            items,
            name,
            required
          }) => (
            <OrderInformationSection
              label={label}
              labelKey={labelKey}
              showSum={showSum}
              sumLabel={sumLabel}
              showBackground={showBackground}
              items={items}
              required={required}
              name={name}
              errors={errors}
            />
          )
        )}
        {orderInformationPage2[clientType]?.advisorNotes.enabled && (
          <AdvisorNotes
            name={'orderInformationPage2AdvisorNotes'}
            required={orderInformationPage2[clientType]?.advisorNotes.required}
            header={i18n(
              'orderExecution.orderInformationPage2.advisorNotes.header'
            )}
            customPlaceholder={i18n(
              'orderExecution.orderInformationPage2.advisorNotes.placeholder'
            )}
            infoTooltipText={i18n(
              'orderExecution.orderInformationPage2.advisorNotes.infoTooltip'
            )}
            className={classes.advisorNotes}
          />
        )}
        <div className={classes.errors}>
          <NextButtonError allErrors={allErrors} />
        </div>
        <OrderExecutionActionButtons
          pageId={RoboAdviceOrderExecutionPages.orderInformationPage2}
          onNavButtonClick={onNavButtonClick}
        />
      </>
    )
  );
};

export default OrderInformationPage2;
