import { isNil } from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AdviceSessionParams } from '../../../../shared/constants/session';
import { useCustomerConfig } from '../../../../sharedModules/customerConfig/components/useCustomerConfig';
import { useSessionStore } from '../../session/services/sessionStore';
import {
  useGoalsStore,
  selectors,
  Goal
} from '../../shared/services/goalsStore';
import {
  calculateInternalPortfolioTotalValue,
  calculateMonthlySurplus
} from '../services/mapping';
import {
  useFinancialSituationValues,
  useAmountForAdviceValues
} from 'features/roboAdvice/adviceSession/financialSituation/services/selectors';
import { useIsFollowUpEnabled } from 'features/roboAdvice/shared/components/useIsFollowUpEnabled';
import Tooltip from 'features/shared/components/tooltip/index';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { filterNil } from 'features/shared/utils/filters';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const BAR_HEIGHT = 15;
export const BAR_WIDTH = 226;

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.boxBackgroundColor_1,
    border: `1px solid ${theme.boxBackgroundColor_2}`,
    padding: '32px',
    borderRadius: '10px',
    width: 'min-content',
    [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
      minWidth: '502px'
    }
  },
  header: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '40px',

    '& + &': {
      marginTop: '25px'
    }
  },
  subHeader: {
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    color: theme.secondaryColor,
    whiteSpace: 'nowrap'
  },
  barRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px'
  },
  barBackground: {
    position: 'relative',
    width: BAR_WIDTH,
    height: BAR_HEIGHT,
    backgroundColor: theme.disabledTextColor
  },
  barNumber: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  tooltipContainer: {
    height: BAR_HEIGHT,
    position: 'relative',
    transition: '.5s width'
  },
  barMiddleLine: {
    width: '1px',
    height: BAR_HEIGHT,
    backgroundColor: theme.primaryColor,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
}));

type Props = {
  selectedGoal?: Goal;
};

const AllocatedAmount = ({ selectedGoal }: Props) => {
  const classes = useStyles();
  const theme: any = useTheme();
  const i18n = useI18n();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const {
    roboAdviceForm: {
      financialSituation: {
        accounts: {
          sync: {
            enabled: isAccountsSyncEnabled,
            mapping: {
              company: accountsSyncCompanyMapping,
              person: accountsSyncPersonMapping
            }
          }
        }
      }
    },
    analyticsComponents: { isTransactionListHidden }
  } = useCustomerConfig();
  const { clientType } = useParams<AdviceSessionParams>();
  const sessionStore = useSessionStore();
  const { isFollowUpEnabled } = useIsFollowUpEnabled();

  const financialSituationValues = useFinancialSituationValues();
  const { currentSum, originalSum } = useAmountForAdviceValues();

  const monthlyDeposit = useGoalsStore(selectors.getTotalMonthlyDeposit);
  const firstDeposit = useGoalsStore(selectors.getTotalFirstDeposit);
  const internalHolding = useGoalsStore(selectors.getTotalInternalHolding);

  const monthlySurplus = calculateMonthlySurplus(
    financialSituationValues?.monthlySurplus
  );

  const internalPortfolioTotalValue = useMemo(() => {
    if (isAccountsSyncEnabled && !isTransactionListHidden) {
      return calculateInternalPortfolioTotalValue({
        financialSituationValues,
        clientType,
        accountsSyncPersonMapping,
        accountsSyncCompanyMapping
      });
    }
    return 0;
  }, [financialSituationValues]);

  const amountForAdvice =
    currentSum !== originalSum
      ? currentSum
      : originalSum === 0
      ? null
      : originalSum;

  const firstDepositLimit = isAccountsSyncEnabled
    ? (amountForAdvice ?? 0) - (internalPortfolioTotalValue ?? 0)
    : amountForAdvice;

  const addOnLimit = amountForAdvice
    ? amountForAdvice - (internalPortfolioTotalValue ?? 0)
    : 0;

  const rows = [
    {
      title: i18n('roboAdvice.savingsPlan.monthlyDeposit'),
      statusBarWidth: (monthlyDeposit / ((monthlySurplus || 0) * 2)) * 100,
      barNumber: isNil(monthlySurplus)
        ? '0'
        : formatNumber(cultureCode, monthlySurplus, 0, 0),
      tooltipNumber: formatNumber(cultureCode, monthlyDeposit, 0, 0),
      exactValue: false
    },
    sessionStore.followUpId && isFollowUpEnabled
      ? {
          title: i18n('roboAdvice.advisory.allocatedAmount.availableForAddOn'),
          statusBarWidth: isNil(addOnLimit)
            ? firstDeposit
              ? 100
              : 0
            : (firstDeposit / (addOnLimit * 2)) * 100,
          barNumber: isNil(addOnLimit)
            ? '0'
            : formatNumber(cultureCode, addOnLimit, 0, 0),
          tooltipNumber: formatNumber(cultureCode, firstDeposit, 0, 0),
          exactValue: false
        }
      : {
          title: i18n('roboAdvice.savingsPlan.firstDeposit'),
          statusBarWidth: isNil(firstDepositLimit)
            ? firstDeposit
              ? 100
              : 0
            : (firstDeposit / (firstDepositLimit * 2)) * 100,
          barNumber: isNil(firstDepositLimit)
            ? '0'
            : formatNumber(cultureCode, firstDepositLimit, 0, 0),
          tooltipNumber: formatNumber(cultureCode, firstDeposit, 0, 0),
          exactValue: false
        },
    sessionStore.followUpId && isFollowUpEnabled
      ? {
          title: i18n('roboAdvice.savingsPlan.withdrawal'),
          statusBarWidth: selectedGoal?.data.internalHolding
            ? ((selectedGoal?.data.followUpWithdrawal || 0) /
                (selectedGoal.data.internalHolding * 2)) *
              100
            : 0,
          barNumber: isNil(selectedGoal?.data.internalHolding)
            ? '0'
            : formatNumber(
                cultureCode,
                selectedGoal?.data.internalHolding,
                0,
                0
              ),
          tooltipNumber: formatNumber(
            cultureCode,
            selectedGoal?.data.followUpWithdrawal ?? 0,
            0,
            0
          ),
          exactValue: false
        }
      : null
  ].filter(filterNil);

  if (!isTransactionListHidden) {
    rows.unshift({
      title: i18n('roboAdvice.advisory.allocatedAmount.internalPortfolio'),
      statusBarWidth:
        (internalHolding / ((internalPortfolioTotalValue || 0) * 2)) * 100,
      barNumber: isNil(internalPortfolioTotalValue)
        ? '0'
        : formatNumber(cultureCode, internalPortfolioTotalValue, 0, 0),
      tooltipNumber: formatNumber(cultureCode, internalHolding, 0, 0),
      exactValue: true
    });
  }

  const getBarColor = (statusBarWidth: number, exactValue: boolean) => {
    if (exactValue) {
      return statusBarWidth === 50
        ? theme.successColor
        : theme.errorNegativeColor;
    }
    return statusBarWidth <= 50 ? theme.successColor : theme.errorNegativeColor;
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {i18n('roboAdvice.advisory.allocatedAmount.header')}
      </div>

      {rows.map(
        ({ title, statusBarWidth, barNumber, tooltipNumber, exactValue }) => (
          <div className={classes.row} key={title}>
            <div className={classes.subHeader}>{title}</div>

            <div className={classes.barRoot}>
              <div className={classes.barNumber}>{barNumber}</div>

              <div className={classes.barBackground}>
                <Tooltip
                  content={tooltipNumber}
                  infoTooltip
                  topCorner
                  trigger={
                    <div
                      className={classes.tooltipContainer}
                      style={{
                        width:
                          (BAR_WIDTH *
                            (statusBarWidth <= 100 ? statusBarWidth : 100)) /
                          100,
                        backgroundColor: getBarColor(statusBarWidth, exactValue)
                      }}
                      data-testid="allocated-amount-tooltip-container"
                    />
                  }
                  triggerButtonStyles={{ justifyContent: 'flex-start' }}
                />

                {barNumber && <div className={classes.barMiddleLine}></div>}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AllocatedAmount;
