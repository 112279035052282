import Dropdown from '../../../../shared/components/dropdown';
import { useCustomerConfig } from '../../../../sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from '../../../../sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from '../../../../sharedModules/styles/components/styles';
import { useSaveAdviceSession } from './useSaveAdviceSession';
import Button, { ButtonType } from 'features/shared/components/button';

const useStyles = createUseStyles(theme => ({
  menuOffset: {
    marginTop: '0',
    paddingTop: '0',
    paddingBottom: '0',
    borderRadius: '0'
  }
}));

const AdviceSessionTypeSelectDropdown = ({ options }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const saveAdviceSession = useSaveAdviceSession();
  const {
    roboOrderFlow,
    adviceFlows,
    tenantSettings: { advisoryFlow }
  } = useCustomerConfig();

  return (
    <>
      {roboOrderFlow.enabled && advisoryFlow.enabled ? (
        <Dropdown
          input={props => (
            <div ref={props.forwardedRef}>
              <Button {...props} buttonType={ButtonType.tertiary}>
                + {i18n('roboAdvice.client.addNewAdvice')}
              </Button>
            </div>
          )}
          options={options}
          menuProps={{
            offsetBottomClassName: classes.menuOffset
          }}
          onChange={item => saveAdviceSession(null, item)}
        />
      ) : (
        <>
          {advisoryFlow.enabled && (
            <Button
              onClick={() => saveAdviceSession(null, adviceFlows[0].name)}
              buttonType={ButtonType.tertiary}
            >
              + {i18n('roboAdvice.client.addNewAdvice')}
            </Button>
          )}
          {roboOrderFlow.enabled && (
            <Button
              onClick={() => saveAdviceSession(null, adviceFlows[1].name)}
              buttonType={ButtonType.tertiary}
            >
              + {i18n('roboAdvice.client.addNewAdvice')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default AdviceSessionTypeSelectDropdown;
