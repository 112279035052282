import { calculatorSimpleMapping } from '@quantfoliorepo/ui-components';
import axios from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentDate } from '../../../../shared/utils';
import { useExpectedValueChartStore } from '../../../expectedValueChart';
import { readCalculatorSimple } from '../../../shared/api';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import {
  getHorizonAnalysisProjectionYearsValue,
  getIsFormComplete
} from '../../services/selectors.js';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadExpectedValueChartData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const {
    timeHorizonConfig,
    analyticsComponents: {
      expectedValue: { bankReturn }
    }
  } = useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<any>();
  const readExpectedValueChartData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalPageStore = useProposalPageStore.getState();
    const expectedValueChartStore = useExpectedValueChartStore.getState();
    const { goals: storeGoals } = useGoalsStore.getState();

    const isFormComplete = getIsFormComplete();
    if (!isFormComplete) {
      expectedValueChartStore.resetChartData();
      proposalPageStore.setPageStatus(
        'readExpectedValueChartData',
        PageStatuses.failed
      );

      return;
    }

    try {
      proposalPageStore.setPageStatus(
        'readExpectedValueChartData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();

      const isUpdated =
        !!proposalPageStore.goalsToUpdate.readExpectedValueChartData.length;

      const goals = isUpdated
        ? proposalPageStore.goalsToUpdate.readExpectedValueChartData
        : storeGoals;

      for (const goal of goals) {
        const goalChartData = portfolioChartStore.getChartData(
          goal.goalId,
          goal.data.isPortfolioCustom
        );

        const analyzeRiskReturn = {
          risk: goalChartData.expectedVolatility,
          return: goalChartData.expectedAnnualReturn
        };

        const timeHorizon = getHorizonAnalysisProjectionYearsValue({
          timeHorizonConfig,
          timeHorizon: goal.data.timeHorizon
        });

        const readCalculatorSimpleResponse = await readCalculatorSimple(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            expected_return: calculatorSimpleMapping.getReturn({
              dataSources: {
                analyzeRiskReturn
              }
            }),
            expected_return_bank: bankReturn,
            initial_deposit: goal.data.firstDeposit,
            monthly_deposit: goal.data.monthlyDeposit,
            time_horizon: timeHorizon
          }
        );

        const forecast = readCalculatorSimpleResponse.data.forecast;

        const data = {
          forecast,
          currentDate,
          timeHorizon,
          firstDeposit: goal.data.firstDeposit,
          goalId: goal.goalId
        };

        isUpdated
          ? expectedValueChartStore.editGoalData(data)
          : expectedValueChartStore.addGoalData(data);
      }

      if (isUpdated) {
        proposalPageStore.setGoalsToUpdate('readExpectedValueChartData', []);
      }

      proposalPageStore.setPageStatus(
        'readExpectedValueChartData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        expectedValueChartStore.resetChartData();
        proposalPageStore.setPageStatus(
          'readExpectedValueChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readExpectedValueChartData;
}
